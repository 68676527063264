import React from 'react';
import { PageHeader, Container, PageFooter } from 'components/commons';
import classnames from 'classnames';

const LayoutA = ({ children, padding, logoRedirectUrl }) => {
  return (
    <div className={classnames('flex flex-col h-full bg-gray-lightest')}>
      <PageHeader logoRedirectUrl={logoRedirectUrl} />
      <Container
        padding={padding}
        className={classnames('flex justify-center items-center flex-grow p-xl mt-lg')}
      >
        {children}
      </Container>
      <PageFooter />
    </div>
  );
};

export default LayoutA;

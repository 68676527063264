import axios from 'axios';

axios.interceptors.request.use(
  config => {
    console.log(config);
    console.log(config.params);
    const isAuthAPI =
      config.url && (config.url.endsWith('/login') || config.url.endsWith('/register'));
    if (localStorage.getItem('accessToken') && !isAuthAPI) {
      config = {
        ...config,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            config.data?.accessTokenV3 ||
              config.params?.accessTokenV3 ||
              config.url.toString().includes('/profile/avatar')
              ? 'accessTokenV3'
              : 'accessToken',
          )}`,
          ...config.headers,
        },
      };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

import React from "react";
import { Button , Title,  } from "components/commons";
import lang from "translations";

import { Path } from "paths";
import { useHistory } from "react-router-dom";
import { LayoutA } from "components/layouts";

const NotFound = () => {

  const history = useHistory();

  return (
    <LayoutA>
			<div>
      	<Title xl>{lang.pageNotFound}</Title>
        <div className="flex justify-center center">
          <Button
            className="mt-xl text-pelorous-dark"
            onClick={() => {
              history.push(Path.SLASH)
            }}
          >
            {lang.goToHome}
          </Button>
        </div>
    	</div>
		</LayoutA>
  );

};

export default NotFound;
import React, { useCallback, useRef, useState } from 'react';
import { Image, Title, Text, Input, Button, Field } from 'components/commons';
import { CreateAccountNotifBg, Kangaroo, KangarooSitting } from 'images';
import { useForm, useApi, useRouter, useMount } from 'hooks';
import { initialState } from './sign-in-form.state';
import { signIn } from 'apis';
import { redirectTo } from 'services';
import lang from 'translations';
import Validation from 'services/validation.service';
import { Path } from 'paths';
import { environment } from 'environments/environment';
import { StyleType } from 'enums';
import { notification } from 'antd';

const SignOut = () => {
  const passwordRef = useRef();
  const [verified, setVerified] = useState();
  const [checkEmail] = useState();
  const { query } = useRouter();
  const { email: emailQuery, type } = query || {};
  const { fields, modifyField, applyFieldErrors, clearForm, submitForm, modifyForm } = useForm({
    initialState,
  });

  const { email, password } = fields;
  const [redirecting, setRedirecting] = useState(false);

  // const { request: verifyEmailRequest, loading: verifyingEmail } = useApi({
  //   api: verifyEmail,
  //   handleOwnError: {
  //     badrequest: {
  //       2003: () => {
  //         applyFieldErrors({
  //           email: lang.emailDoesntHaveRecord,
  //         });
  //       },
  //       3037: () => {
  //         setCheckEmail(true);
  //       },
  //       3066: () => {
  //         applyFieldErrors({
  //           email: lang.accountCantSeemToFind,
  //         });
  //       },
  //     },
  //   },
  // });

  const { request: signInRequest, loading: signingIn } = useApi({
    api: signIn,
    handleOwnError: {
      unauthorized: {
        1002: () => {
          applyFieldErrors({
            password: lang.enteredPasswordIncorrect,
          });
        },
      },
    },
  });

  const handleVerifyEmail = useCallback(async () => {
    // await verifyEmailRequest({ email: fields.email.value });

    // passwordRef.current.focus();
    modifyField('password', { validations: [Validation.required()] });
    setVerified(true);
  }, [modifyField]);

  const verifyEmailCb = useCallback(() => {
    submitForm(handleVerifyEmail);
  }, [handleVerifyEmail, submitForm]);

  const clearEmailCb = useCallback(() => {
    clearForm();
    modifyField('password', { value: '', validations: [] });
    setVerified(false);
  }, [clearForm, modifyField]);

  const handleSignIn = useCallback(async () => {
    const res = await signInRequest({ email: email.value, password: password.value });
    setRedirecting(true);
    localStorage.setItem('accessToken', res?.accessToken);
    if (type === 'VENUE') {
      window.location.href = `${environment.VENUE_PAGE}?accessToken=${
        res?.accessToken
      }&lang=${localStorage.getItem('locale')}`;
      return;
    }
    redirectTo(Path.SLASH);
  }, [email.value, password.value, signInRequest, type]);

  const signInCb = useCallback(async () => {
    submitForm(handleSignIn);
  }, [submitForm, handleSignIn]);

  useMount(() => {
    if (emailQuery) {
      modifyForm({
        email: { value: emailQuery },
        password: { validations: [Validation.required()], pristine: true },
      });
      setVerified(true);
      // passwordRef.current.focus();
    }
  });

  const openCreateAccountNotification = placement => {
    const buttons = (
      <>
        <Button
          type={StyleType.Secondary}
          onClick={() => {
            redirectTo(`${environment.POUCH_CONNECT}register`);
          }}
        >
          {lang.createAccount}
        </Button>
        <Button
          className="mx-sm"
          type={StyleType.Ghost}
          onClick={() => {
            redirectTo('https://pouchnation.com/pricing/');
          }}
        >
          {lang.viewPlansAndPricing}
        </Button>
      </>
    );

    notification.info({
      message: <Title className="-ml-xl">{lang.newToPouchNation}</Title>,
      description: (
        <div className="-ml-xl">
          <Text className="pb-sm">
            {lang.subscribeAndRegister}
            {''} <strong className="text-sm">{lang.freeTrial2}</strong>
          </Text>
          {buttons}
        </div>
      ),
      key: 'create-acc-notif',
      placement,
      className: '-ml-sm',
      duration: 0,
      closeIcon: <div></div>,
      style: {
        width: 400,
        height: 128,
        backgroundImage: `url(${CreateAccountNotifBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        borderRadius: 5,
        bottom: 25,
      },
    });
  };

  return (
    <div className="mt-lg" onLoad={() => openCreateAccountNotification('bottomLeft')}>
      {checkEmail ? (
        <div className="w-full flex flex-col items-center">
          <Image className="mb-md text-center" src={KangarooSitting} />
          <Title className="mb-lg" xl>
            {lang.checkYourEmail}
          </Title>
          <Text>{lang.looksLikeAlreadyInSystem}</Text>
          <lang.Translate
            className="text-sm"
            text={lang.checkYourEmailAt}
            items={[<span className="font-medium text-sm">{fields.email.value}</span>]}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center text-center w-11/12 ml-sm sm:w-96 ml-0">
          <Image className="mb-md" src={Kangaroo} />
          <Title className="mb-lg" xl>
            {verified ? lang.welcomeBack : lang.letsGetStarted}
          </Title>
          {!verified && <Text className="mb-md">{lang.enterEmailToLogin}</Text>}
          <Field className="w-full mb-md" message={email.message}>
            <Input
              center
              {...email}
              hoverClearable={verified}
              readOnly={verified}
              onChange={modifyField}
              onEnter={verifyEmailCb}
              onClear={clearEmailCb}
            />
          </Field>
          {verified && (
            <Field className="w-full mb-md" message={password.message}>
              <Input
                ref={passwordRef}
                center
                {...password}
                inputType="password"
                onChange={modifyField}
                onEnter={signInCb}
              />
            </Field>
          )}
          <Button
            onClick={verified ? signInCb : verifyEmailCb}
            loading={signingIn || redirecting}
            disabled={signingIn || redirecting}
          >
            {verified ? lang.logIn : lang.getStarted}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SignOut;

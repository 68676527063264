import Amplify from 'aws-amplify';
import { environment } from 'environments/environment';
import queryString from 'querystring';
// import { isAlphaNumeric } from 'services/text.service';
// import { isURI } from 'services/url.service';

const configure = {
  Auth: {
    region: 'ap-southeast-1',
    userPoolId: environment.AWS.userPoolId,
  },
};

const { search } = window.location;

const query = queryString.parse(search.slice(1));
const { cognitoClientId, responseType, scope, redirectUrl } = query || {};

const accountsPageConfig = {
  Auth: {
    region: 'ap-southeast-1',
    userPoolId: environment.AWS.userPoolId, //'ap-southeast-1_w80IINA0k',
  },
  userPoolWebClientId: environment.AWS.userPoolWebClientId, // '2f6uk9sd2jk8fg3tfqmhamlsus',
  oauth: {
    domain: environment.AWS.oauthDomain, // 'staging-auth-connector-pouchnation-com.auth.ap-southeast-1.amazoncognito.com',
    scope: [
      'booking/user',
      'connector/user',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: environment.AWS.redirectSignIn,
    redirectSignOut: environment.AWS.redirectSignOut,
    responseType: 'token',
  },
};

const newConfig = {
  ...configure,
  userPoolWebClientId: cognitoClientId,
  oauth: {
    domain: environment.AWS.oauthDomain,
    scope: scope
      ? scope.split(' ')
      : [
          'booking/user',
          'connector/user',
          'email',
          'openid',
          'profile',
          'aws.cognito.signin.user.admin',
        ],
    redirectSignIn: redirectUrl,
    redirectSignOut: redirectUrl,
    responseType: responseType ?? 'token',
  },
};

// const isClientIdNilOrEmpty =
//   client_id === undefined || client_id === null || client_id?.length === 0;
// const isRedirectUriNilOrEmpty =
//   redirect_uri === undefined || redirect_uri === null || redirect_uri?.length === 0;

// const invalidAwsQuery =
//   isClientIdNilOrEmpty ||
//   isRedirectUriNilOrEmpty ||
//   client_id?.length !== 26 ||
//   !isURI(redirect_uri) ||
//   !isAlphaNumeric(client_id);

if (cognitoClientId && redirectUrl) {
  Amplify.configure(newConfig);
} else {
  Amplify.configure(accountsPageConfig);
}

export const profilePaths = {
  PROFILE: '',
  PROFILE_AVATAR: 'avatar',
  PROFILE_USERS_PROFILE_ID: id => `users/profiles/${id}`,
  PROFILE_CHANGE_PASSWORD: 'password',
  PROFILE_CHECK_USER: email => `check?email=${email}`,
  PROFILE_REGISTER: 'register',
  FORGOT_PASSWORD: email => `forgot-password?email=${email}&platform=ACCOUNTS`,
  RESET_PASSWORD: 'reset-password',
};

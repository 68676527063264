import React, { useCallback } from 'react';
import { Button, Loader, Text, Title, Toast, Icon } from 'components/commons';
import lang from 'translations';

import LayoutC from 'components/layouts/layout-c';
import { useApi, useMount, useRouter } from 'hooks';
import { confirmSignUp, signInV3 } from 'apis';
import { environment } from 'environments/environment';
import { registerAccount } from 'apis/account.api';
import { ClientId } from 'enums';
// import { getCurrentAuthenticatedUser } from 'apis/aws.api';
import { getAccessToken, getIdToken } from 'services/localStorage.service';
import { redirectToCallbackURI } from 'services/url.service';

const Verify = () => {
  const { query } = useRouter();
  const { code, username } = query;

  const [state, setState] = React.useState({
    loading: true,
    verificationInfo: {
      error: false,
      message: undefined,
    },
  });

  const clientId = localStorage.getItem('clientId');
  const accessToken = getAccessToken(clientId);
  const idToken = getIdToken(clientId);

  useMount(async () => {
    try {
      const result = await confirmSignUp({ username, code });

      // getCurrentAuthenticatedUser()
      //   .then(user => {
      //     const userSession = user.getSignInUserSession();
      //     const accessToken = userSession?.accessToken?.jwtToken;
      //     const idToken = userSession?.idToken?.jwtToken;
      //     console.log(idToken);
      //   })
      //   .catch(error => {
      //     handleResponseError(error);
      //   });
      // console.log('flag 2');

      setState(prev => ({
        loading: false,
        verificationInfo: { error: false, message: result.message },
      }));

      if (clientId === ClientId.VenueDashboard) {
        if (!state.verificationInfo.error) {
          createAccountInV3();
        } else {
          loginAccountInV3();
        }
      } else {
        goToApplication();
      }
    } catch (error) {
      setState(prev => ({
        loading: false,
        verificationInfo: { error: true, message: error.message },
      }));

      if (clientId === ClientId.VenueDashboard) {
        loginAccountInV3();
      }
    }
  });

  const goToApplication = useCallback(async () => {
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      redirectToCallbackURI({ redirectUrl, idToken, accessToken });
    } else {
      window.location.href = `${environment.VENUE_PAGE}?lang=${localStorage.getItem('locale')}`;
    }
  }, [idToken, accessToken]);

  const { request: submit } = useApi({
    api: registerAccount,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: signInRequest } = useApi({
    api: signInV3,
    handleOwnError: true,
  });

  const loginAccountInV3 = useCallback(async () => {
    console.log('logging in ');
    const params = JSON.parse(localStorage.getItem('signUpFormParams'));
    const clientId = localStorage.getItem('clientId');

    const res = await signInRequest({
      email: params.email,
      password: params.password,
      clientId: clientId ?? 'POUCH_ID',
    });

    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      let url = new URL(redirectUrl);
      let parameters = new URLSearchParams(url.search.slice(1));
      parameters.append('accessToken', res?.accessToken);
      window.location.href = `${redirectUrl}?${parameters.toString()}`;
    }
  }, [signInRequest]);

  const createAccountInV3 = useCallback(async () => {
    const registrationMark = localStorage.getItem('registrationMark');
    const params = JSON.parse(localStorage.getItem('signUpFormParams'));
    const clientId = localStorage.getItem('clientId');

    console.log(registrationMark);
    console.log(params);
    console.log(clientId);

    params.profile = {
      firstName: params.firstName,
      lastName: params.lastName,
      registrationMark: registrationMark,
    };

    if (clientId) {
      params.clientId = clientId;
      if (clientId === ClientId.VenueDashboard) {
        params.profile.profileType = 'VENUE';
        params.profile.uniqueType = 'VENUE';
        params.profile.forType = 'VENUE';
      }
    }

    await submit(params);
    Toast({
      content: lang.accountCreatedSuccessfully,
      success: true,
      icon: 'check',
    }).open();

    loginAccountInV3();
  }, [loginAccountInV3, submit]);

  return state.loading ? (
    <Loader />
  ) : (
    <LayoutC
      leftChild={
        <div>
          <Title xl>
            {state.verificationInfo.error
              ? lang.verifyEmailAddressFailed
              : lang.verifyEmailAddressSuccessful}
          </Title>
          <Text>{state.verificationInfo.message}</Text>

          {state.loading ? (
            <div className="flex mt-md">
              <Icon loading />
            </div>
          ) : (
            <div className="flex mt-md">
              <Text className="text-gray-600">{lang.proceedToYourApplication}</Text>
              <Button className="text-pelorous-dark" onClick={goToApplication}>
                {lang.continueToYourAccount}
              </Button>
            </div>
          )}
        </div>
      }
    ></LayoutC>
  );
};

export default Verify;

const locale = {
  account: 'Account',
  accountCreatedSuccessfully: 'Account created successfully',
  allRightsReserved: 'All rights reserved.',
  almostDoneResetPassword: 'Almost done! Enter your new password.',
  alreadyHaveAnAccount: 'Already have an account?',
  and: 'and',
  andClickResetPassword: 'and click the reset password link to reset your password',
  backToLogin: 'Back to Login',
  byGettingStarted: 'By getting started, you agree to the PouchNATION',
  canNotLoginSocialNetwork: 'Can not login with social network',
  cancel: 'Cancel',
  changePassword: 'Change Password',
  changePhoto: 'Change Photo',
  changesSaved: 'Changes saved.',
  chooseAPassword: 'Use a strong password that you don’t use elsewhere.',
  code: 'Code',
  completeYourDetails: 'Complete your details',
  confirmNewPassword: 'Confirm New Password',
  confirmPassword: 'Confirm password',
  contactUs2: 'Contact Us',
  continueToYourAccount: 'Continue to your Account',
  continueToYourPNAccount: 'Continue to your PouchNATION account',
  continueWithFacebook: 'Continue with Facebook',
  continueWithGoogle: 'Continue with Google',
  copyrightYear: 'Copyright © 2021',
  createAccount: 'Create Account',
  createAnAccountNow: 'Create an Account Now',
  createYourAccount: 'Create Your Account',
  currentPassword: 'Current Password',
  currentPasswordAndNewPasswordMatched: 'Current and new password is matched',
  didNotReceiveAnyThing: "Didn't receive anything?",
  discard: 'Discard',
  discardChanges: 'Discard Changes',
  dontHaveAccountYet: "Don't have account yet?",
  edit: 'Edit',
  emailAddress: 'Email Address',
  emailAlreadyHaveAnAccount: 'This email address already has an account. Please try to login instead.',
  emailCanNotBeEmpty: 'Email cannot be empty',
  emailNotFound: 'Email not found',
  emailNotMatchAccount: 'This email does not match a PouchNATION account.',
  emailVerification: 'Verify Email Address',
  enterEmailToReset: 'Enter the email address associated with your account to reset your password.',
  enterNewPassword: 'Enter your new password',
  enterVerificationCode: 'Enter Verification Code',
  enterYourEmailAddress: 'Enter your email address',
  enterYourFirstName: 'Enter your first name',
  enterYourLastName: 'Enter your last name',
  enterYourPassword: 'Enter your password',
  experiencePouchVenues:
    'Experience the power of PouchVENUEs with menu creation, inventory management, guest management and so much more!',
  feedback: 'Feedback',
  finishSettingUpYourAccount: 'Finish setting up your PouchNATION account.',
  firstName: 'First Name',
  forgotPassword: 'Forgot Password?',
  freeTrial: '30-day free trial!',
  freeTrial2: '30-day Free Trial',
  getStarted: 'Get started',
  getStartedWithA: 'Get Started with a',
  glossary: 'Glossary',
  goToHome: 'Go to home page',
  goToVenue: 'Go to Venue Dashboard',
  haveNotReceivedIt: "Haven't received yet?",
  ifYouLeaveThisPage:
    'If you leave this page, all unsaved changes will be lost. Are you sure you want to discard changes?',
  incorrectLoginParams: 'Incorrect email or password',
  invalidFileType: 'Invalid file type. Allowed file types are JPG or PNG.',
  invalidLogin: 'Invalid Login',
  invalidOptions: 'Invalid option(s).',
  isAreserveWord: 'is a reserve word.',
  lastName: 'Last Name',
  logIn: 'Log in',
  loginHasEnded: 'Your login session has already ended. Please login again.',
  loginInstead: 'Log in instead',
  loginNow: 'Login Now',
  loginSessionEnded: 'Login Session Ended',
  logout: 'Logout',
  looksLikeYouEncounteredSomething:
    'Looks like you encountered an error, we track these errors automatically. For the mean time, try refreshing.',
  maximumLengthCharacters: 'Maximum {0} character(s).',
  maximumValueIs: 'Maximum value is',
  minOfNCharacters: 'Minimum of {0} characters',
  minimum: 'Minimum',
  minimumCharactersAllowed: 'Minimum of {0} characters allowed',
  minimumOfNCharacters: 'Minimum of 8 characters',
  minimumValueIs: 'Minimum value is',
  newPassWord: 'New Password',
  newToPouchNation: 'New to PouchNATION?',
  ok: 'Ok',
  pageNotFound: 'Page Not Found',
  password: 'Password',
  passwordCanNotBeEmpty: 'Password cannot be empty',
  passwordDoesNotMatch: 'The passwords you entered do not match',
  passwordIncorrect: 'The password you entered is incorrect.',
  passwordResetExpired: 'Reset Password Request Expired!',
  passwordResetSuccessful: 'Password Reset Successful!',
  pleaseCheck: 'Please check',
  pleaseEnterValidEmail: 'Please enter a valid email address.',
  pleaseEnterYourEmail: 'Please enter your email address to start creating an account.',
  pleaseEnterYourEmailToReset: 'Please enter your email address to reset your account.',
  pouchnation: 'PouchNATION',
  privacy: 'Privacy',
  privacyPolicy2: 'Privacy Policy',
  rememberedYourCurrentPassword: 'Remembered your current password?',
  resend: 'Resend Link',
  resendResetLink: 'Resend Reset Link',
  resetPassword: 'Reset Password',
  resetPasswordLinkSent: 'Reset Password Link Sent!',
  retypePassword: 'Retype Password',
  roadMap: 'Roadmap',
  save: 'Save',
  sendResetLink: 'Send Reset Link',
  sentCodeInfo: '',
  setUpAPassword: 'Set up a password',
  setUpYourPouchNationAccount: 'Setup your PouchNATION account.',
  setUpYourVenue: 'Setup Your Venue',
  shouldBeANumber: 'Should be a number.',
  signIn: 'Sign In',
  somethingWentWrong: 'Something went wrong.',
  subscribeAndRegister: 'Subscribe and register now to get a',
  termsAndConditions: 'Terms and Conditions',
  termsOfUse: 'Terms of Use',
  thisEmailIsInvalid: 'This email is invalid.',
  thisFieldIsRequired: 'This field is required.',
  today: 'Today',
  unbaleToUploadMoreThanMb: 'Unable to upload more than 1mb.',
  uploadPhoto: 'Upload Photo',
  valueBetween: 'Value should be between',
  values: 'values',
  verifyEmailAddressFailed: 'Verify Email Address is already confirmed.',
  verifyEmailAddressSuccessful: 'Verify Email Address Successful!',
  verifyEmailDone: 'Your email address has been verified',
  viewPlansAndPricing: 'View Plans and Pricing',
  youCanNowUseNewPassword:
    'Your password has been changed successfully! You can now use your new password to login to your account.',
  youHaveUnsavedChanges: 'You have unsaved changes on this page.',
  yourPasswordRequestExpired: 'Your password reset request has expired.',
};
export default Object.freeze(locale);

import { CreateAccount } from "modules";
import { Route, Switch } from "react-router-dom";
import { Path } from "paths";
import React from "react";

const AccountPage = () => {
  return (
    <Switch>
      <Route path={Path.CREATE_ACCOUNT} component={CreateAccount} />
    </Switch>
  );
};

export default AccountPage;

import React, { useContext } from 'react';
import { Dropdown, Text, Avatar } from '..';
import { ProfileContext } from 'contexts';
import styles from './user-nav.module.scss';
import classnames from 'classnames';
import { reloadPage } from 'services';
// import { v4 as uuidv4 } from 'uuid';
// import { useRouter } from 'hooks';
// import { Path } from 'paths';
import lang from 'translations';

const UserNav = () => {
  const { profile, loading } = useContext(ProfileContext) || {};
  const { firstName, lastName, email, avatar: profileAvatar } = profile || {};
  const fullName = `${firstName || ''} ${lastName || ''}`.trim();
  const avatar = {
    className: 'm-auto',
    phrases: [firstName, lastName],
    loading,
    image: profileAvatar,
  };

  if (!profile) {
    return null;
  }

  return (
    <Dropdown
      trigger="click"
      id="user-nav"
      placement="bottomRight"
      className={classnames(styles.container, 'h-full mr-lg')}
      options={[
        {
          value: '0',
          text: (
            <div className="w-72">
              <div className={classnames(styles.borderBottom, 'p-lg text-center')}>
                <div className="text-center mb-md">
                  <Avatar {...avatar} size={14} width="w-14" height="h-14" />
                </div>
                <div>
                  <Text color="text-gray-600" size="text-md" strong className="whitespace-normal">
                    {fullName}
                  </Text>
                </div>
                <div>
                  <Text sm className="whitespace-normal">
                    {email}
                  </Text>
                </div>
              </div>

              <div>
                <div
                  className="py-sm px-md hover:bg-gray-lightest"
                  onClick={() => {
                    localStorage.clear();
                    reloadPage();
                  }}
                >
                  <Text color="text-gray-600">{lang.logout}</Text>
                </div>
              </div>
            </div>
          ),
        },
      ]}
    >
      <div className="flex items-center h-full px-lg">
        <div>
          <Avatar {...avatar} textSize="text-md" />
        </div>
      </div>
    </Dropdown>
  );
};

export default UserNav;

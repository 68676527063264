import { Get, Post } from 'services';
import { ApiPath } from 'paths';
import { PostCognito } from '../services/apiCognito.service';

export const verifyEmail = async ({ email }) => {
  const res = await Get(`${ApiPath.CHECK_EMAIL}`, { email });
  return res.data;
};

export const signInV3 = async ({ email, password, clientId }) => {
  const body = {
    clientId: clientId,
    grantType: 'email',
    email,
    password,
  };
  const res = await Post(`${ApiPath.SIGN_IN}`, body);
  return res.data;
};

export const signInCognito = async request => {
  const res = await PostCognito('user/login', request);
  return res.data;
};

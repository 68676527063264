import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { Path } from 'paths';
import { getAccessToken } from 'services/localStorage.service';

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return getAccessToken() || localStorage.getItem('amplify-redirected-from-hosted-ui') ? (
          <Component {...props} />
        ) : (
          <Redirect to={'/auth'} />
        );
      }}
    />
  );
};

export default AuthorizedRoute;

/* eslint-disable no-unused-vars */
import LayoutC from 'components/layouts/layout-c';
import React, { useCallback, useState, useMemo } from 'react';
import { Field, Input, Button, Text, Title, ButtonLink } from 'components/commons';
import lang from 'translations';
import { initialState } from './reset-password.state';
import { useApi, useForm, useRouter } from 'hooks';
// import classNames from 'classnames';
// import { resendConfirmationCode, signIn, signUp } from 'apis';
// import { mapObject, redirectTo } from 'services';
// import { profileCreateRequest } from 'mappers/profile.mapper';
// import { environment } from 'environments/environment';
import { AWSErrorType } from 'enums';
// import { Path } from 'paths';
import { useHistory, useLocation } from 'react-router-dom';
// import { forgotPasswordSubmit, notFoundEmail } from 'apis/aws.api';
import { resetPassword } from 'apis/profile.api';
import { environment } from 'environments/environment';

const CreateAccount = () => {
  const { query } = useRouter();
  // const location = useLocation();
  // http://localhost:3001/resetPassword?code=739578&username=f8234130-548d-4918-9b49-8ea5a123cd94&clientId=5htbcslsn12022t6s8k50kgg4k&email=dieptx.ptit@gmail.com
  const { token } = query || {};

  const history = useHistory();

  const [forgotState, setForgotState] = useState();
  const [passwordStatus, setPasswordStatus] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const { fields, modifyField, applyFieldErrors, isFormSubmittable } = useForm({
    initialState,
  });

  const { request: resetPasswordRequest, loading } = useApi({
    api: resetPassword,
    handleOwnError: {
      badrequest: true,
    },
  });

  const validatePasswords = useCallback(
    (value1, value2) => {
      const match = value1 === value2;
      if (
        fields.confirmPassword &&
        fields.confirmPassword.value &&
        fields.confirmPassword.value.length > 0
      ) {
        setPasswordsMatch(match);
      } else {
        setPasswordsMatch(true);
      }
    },
    [setPasswordsMatch, fields.confirmPassword],
  );

  const title = useMemo(() => {
    if (forgotState === 'success') {
      return lang.passwordResetSuccessful;
    }
    if (forgotState === 'failed') {
      return lang.passwordResetExpired;
    }
    return lang.resetPassword;
  }, [forgotState]);

  const subTitle = useMemo(() => {
    if (forgotState === 'success') {
      return lang.youCanNowUseNewPassword;
    }
    if (forgotState === 'failed') {
      return lang.yourPasswordRequestExpired;
    }
    return lang.almostDoneResetPassword;
  }, [forgotState]);

  const buttonText = useMemo(() => {
    if (forgotState === 'success') {
      return lang.loginNow;
    }
    if (forgotState === 'failed') {
      return lang.backToLogin;
    }
    return lang.resetPassword;
  }, [forgotState]);

  const goToLogin = useCallback(() => {
    window.location.href = environment.VENUE_URL
  }, []);

  const handleResetPassword = useCallback(async () => {
    try {
      // await forgotPasswordSubmit({ username, code, newPassword: fields.password.value });
      await resetPasswordRequest({
        token: token,
        password: fields.password.value,
      });
      setForgotState('success');
    } catch (error) {
      console.log(error);
      if (error.code === AWSErrorType.InvalidPasswordException) {
        applyFieldErrors({
          password: error?.message,
        });
      } else {
        setForgotState('failed');
      }
    }
  }, [fields.password, token, applyFieldErrors, resetPasswordRequest]);

  return (
    <LayoutC
      leftChild={
        <div>
          <Title xl>{title}</Title>
          <Text>{subTitle}</Text>
          {forgotState === undefined && (
            <>
              <Field
                {...fields.password}
                className="mt-md"
                customLabel={
                  <Text size="text-xs" color="text-gray-500 font-semibold">
                    {lang.newPassword}
                  </Text>
                }
              >
                <Input
                  {...fields.password}
                  inputType={passwordStatus ? 'password' : ''}
                  onChange={(name, obj) => {
                    modifyField(name, obj);
                    validatePasswords(obj.value, fields.confirmPassword.value);
                  }}
                  iconSuffix={
                    <ButtonLink
                      onClick={() => {
                        setPasswordStatus(!passwordStatus);
                      }}
                      prefix
                      icon={passwordStatus ? 'eye-open' : 'eye-close'}
                      iconClassName="text-lg"
                    ></ButtonLink>
                  }
                />
              </Field>
              <Field
                {...fields.confirmPassword}
                customMessage={!passwordsMatch ? <Text error>{lang.passwordNotMatch}</Text> : null}
                className="mt-md"
                customLabel={
                  <Text size="text-xs" color="text-gray-500 font-semibold">
                    {lang.confirmNewPassword}
                  </Text>
                }
              >
                <Input
                  {...fields.confirmPassword}
                  error={!passwordsMatch || fields.confirmPassword.error}
                  inputType={passwordStatus ? 'password' : ''}
                  onChange={(name, obj) => {
                    modifyField(name, obj);
                    validatePasswords(obj.value, fields.password.value);
                  }}
                />
              </Field>
            </>
          )}

          <Button
            className="mt-md"
            onClick={forgotState === undefined ? handleResetPassword : goToLogin}
            disabled={
              forgotState === undefined ? !passwordsMatch || !isFormSubmittable : false || loading
            }
            loading={loading}
          >
            {buttonText}
          </Button>
          {forgotState === undefined && (
            <div className="flex justify-content items-left">
              <Text className="mt-xl text-gray-600">{lang.rememberedYourCurrentPassword}</Text>
              <ButtonLink className="mt-xl text-pelorous-dark ml-sm" onClick={goToLogin}>
                {lang.loginNow}
              </ButtonLink>
            </div>
          )}
        </div>
      }
    ></LayoutC>
  );
};

export default CreateAccount;

import { Get, Post } from "services";
import { ApiPath } from "paths";

export const verifyEmail = async ({ email }) => {
  const res = await Get(`${ApiPath.PROFILE_CHECK_USER(email)}`);
  return res.data;
};

export const registerAccount = async (body) => {
  const res = await Post(`${ApiPath.PROFILE_REGISTER}`, body);
  return res;
};

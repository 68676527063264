const locale = {
  account: 'Conta',
  accountCreatedSuccessfully: 'Conta criada com sucesso',
  allRightsReserved: 'Todos os direitos reservados.',
  almostDoneResetPassword: 'Quase pronto! Digite sua nova senha.',
  alreadyHaveAnAccount: 'Já tem uma conta?',
  and: 'e',
  andClickResetPassword: 'e clique no link de senha de reset para redefinir a sua palavra-passe',
  backToLogin: 'Voltar ao Login',
  byGettingStarted: 'Ao começar, você concorda com a PouchNATION',
  canNotLoginSocialNetwork: 'Não consigo fazer login com rede social',
  cancel: 'Cancelar',
  changePassword: 'Mudar Senha',
  changePhoto: 'Mudar Foto',
  changesSaved: 'Alterações salvas.',
  chooseAPassword: 'Use uma senha forte que você não usa em nenhum outro lugar.',
  code: 'Código',
  completeYourDetails: 'Complete seus dados',
  confirmNewPassword: 'Confirmar Nova Senha',
  confirmPassword: 'Confirmar a senha',
  contactUs2: 'Contato',
  continueToYourAccount: 'Continue para sua Conta',
  continueToYourPNAccount: 'Continue para sua conta PouchNATION',
  continueWithFacebook: 'Continue com o Facebook',
  continueWithGoogle: 'Continue com o Google',
  copyrightYear: 'Copyright © 2021',
  createAccount: 'Criar Conta',
  createAnAccountNow: 'Criar uma conta agora',
  createYourAccount: 'Criar sua Conta',
  currentPassword: 'Senha Atual',
  currentPasswordAndNewPasswordMatched: 'A senha atual e a nova são correspondidas',
  didNotReceiveAnyThing: 'Não recebeu nada?',
  discard: 'Descartar',
  discardChanges: 'Descartar Mudanças',
  dontHaveAccountYet: 'Ainda não tem uma conta?',
  edit: 'Editar',
  emailAddress: 'Endereço de E-mail',
  emailAlreadyHaveAnAccount: 'Este endereço de e-mail já possui uma conta. Por favor tente outro',
  emailCanNotBeEmpty: 'Email não pode estar vazio',
  emailNotFound: 'E-mail não encontrado',
  emailNotMatchAccount: 'Este e-mail não corresponde a uma conta De PouchNATION.',
  emailVerification: 'Verifique o Endereço de E-mail',
  enterEmailToReset: 'Digite o endereço de e-mail associado à sua conta para redefinir sua senha.',
  enterNewPassword: 'Digite sua nova senha',
  enterVerificationCode: 'Digite o Código de Verificação',
  enterYourEmailAddress: 'Insira o seu endereço de email',
  enterYourFirstName: 'Digite seu primeiro nome',
  enterYourLastName: 'Digite seu sobrenome',
  enterYourPassword: 'Coloque sua senha',
  experiencePouchVenues:
    'Experimente o poder dos PouchVENUEs com a criação de menus, gerenciamento de inventário, gerenciamento de convidados e muito mais!',
  feedback: 'Feedback',
  finishSettingUpYourAccount: 'Conclua a configuração de sua conta PouchNATION.',
  firstName: 'Primeiro Nome',
  forgotPassword: 'Esqueceu sua Senha?',
  freeTrial: 'Teste gratuito de 30 dias!',
  freeTrial2: 'Teste Gratuito de 30 dias',
  getStarted: 'Iniciar',
  getStartedWithA: 'Começar com um',
  glossary: 'Glossário',
  goToHome: 'Ir para a página inicial',
  goToVenue: 'Ir para o Painel do Local',
  haveNotReceivedIt: 'Ainda não recebeu?',
  ifYouLeaveThisPage:
    'Se você sair desta página, todas as alterações não salvas serão perdidas. Tem certeza de que deseja descartar as alterações?',
  incorrectLoginParams: 'Senha ou email incorretos',
  invalidFileType: 'Tipo de arquivo inválido. Os tipos de arquivo permitidos são JPG ou PNG.',
  invalidLogin: 'Login Inválido',
  invalidOptions: 'Opção (ões) inválida (s).',
  isAreserveWord: 'é uma palavra de reserva.',
  lastName: 'Último Nome',
  logIn: 'Login',
  loginHasEnded: 'Sua sessão de login já terminou. Por favor faça login novamente.',
  loginInstead: 'Em vez disso, faça login',
  loginNow: 'Fazer Login Agora',
  loginSessionEnded: 'Sessão de Login Encerrada',
  logout: 'Sair',
  looksLikeYouEncounteredSomething:
    'Parece que você encontrou um erro, nós rastreamos esses erros automaticamente. Enquanto isso, tente atualizar.',
  maximumLengthCharacters: 'Máximo de {0} caracteres',
  maximumValueIs: 'O valor máximo é ',
  minOfNCharacters: 'Mínimo de {0} caracteres',
  minimum: 'Mínimo',
  minimumCharactersAllowed: 'Mínimo de {0} caracteres permitidos',
  minimumOfNCharacters: 'Mínimo de 8 caracteres',
  minimumValueIs: 'O valor mínimo é ',
  newPassWord: 'Nova Senha',
  newToPouchNation: 'Novo na PouchNATION?',
  ok: 'Ok',
  pageNotFound: 'Página Não Encontrada',
  password: 'Senha',
  passwordCanNotBeEmpty: 'A senha não pode ficar em branco',
  passwordDoesNotMatch: 'As senhas inseridas não são iguais',
  passwordIncorrect: 'A senha que você digitou está incorreta.',
  passwordResetExpired: 'Solicitação de Redefinição de Senha Expirou!',
  passwordResetSuccessful: 'Redefinição de Senha Bem-Sucedida!',
  pleaseCheck: 'por favor, verifique',
  pleaseEnterValidEmail: 'Por favor insira um endereço de e-mail válido.',
  pleaseEnterYourEmail: 'Por favor, insira seu endereço de e-mail para começar a criar uma conta.',
  pleaseEnterYourEmailToReset: 'Por favor, insira seu endereço de e-mail para redefinir sua conta.',
  pouchnation: 'PouchNATION',
  privacy: 'Privacidade',
  privacyPolicy2: 'Política de privacidade',
  rememberedYourCurrentPassword: 'Lembrou de sua senha atual?',
  resend: 'Reenviar Link',
  resendResetLink: 'Reenviar Link de Redefinição',
  resetPassword: 'Redefinir Senha',
  resetPasswordLinkSent: 'Link de Redefinição de Senha Enviado!',
  retypePassword: 'Digite Novamente a Senha',
  roadMap: 'Plano',
  save: 'Salvar',
  sendResetLink: 'Enviar Link de Redefinição',
  sentCodeInfo: '',
  setUpAPassword: 'Definir uma senha',
  setUpYourPouchNationAccount: 'Configure sua conta PouchNATION.',
  setUpYourVenue: 'Configure Seu Local',
  shouldBeANumber: 'Deve ser um número.',
  signIn: 'Entrar',
  somethingWentWrong: 'Algo deu errado.',
  subscribeAndRegister: 'Inscreva-se e registe-se agora para obter um',
  termsAndConditions: 'Termos e Condições',
  termsOfUse: 'Termos de Uso',
  thisEmailIsInvalid: 'Este email é Inválido.',
  thisFieldIsRequired: 'Este campo é obrigatório.',
  today: 'Hoje',
  unbaleToUploadMoreThanMb: 'Incapaz de carregar mais de 1 MB.',
  uploadPhoto: 'Carregar Foto',
  valueBetween: 'O valor deve estar entre',
  values: 'valor',
  verifyEmailAddressFailed: 'Verifique se o Endereço de E-mail já foi confirmado.',
  verifyEmailAddressSuccessful: 'Verifique o Endereço de E-mail com sucesso!',
  verifyEmailDone: 'Seu endereço de email foi verificado',
  viewPlansAndPricing: 'Ver Planos e Preços',
  youCanNowUseNewPassword:
    'Sua senha foi alterada com sucesso! Agora você pode usar sua nova senha para acessar sua conta.',
  youHaveUnsavedChanges: 'Você tem alterações não salvas nesta página.',
  yourPasswordRequestExpired: 'Sua solicitação de redefinição de senha expirou.',
};
export default Object.freeze(locale);

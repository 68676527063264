import { accountPaths } from './account.path';
import { authPaths } from './auth.path';
import { settingPaths } from './setting.path';

const preparePaths = ({ prefix, paths = {} }) => {
  let newPaths = {};

  for (const [k, path] of Object.entries(paths)) {
    if (typeof path === 'function') {
      newPaths[k] = id => `/${prefix}/${path(id)}`.replace(/\/+/g, '/');
    } else if (path) {
      newPaths[k] = `/${prefix}/${path}`.replace(/\/+/g, '/');
    } else {
      newPaths[k] = `/${prefix}`.replace(/\/+/g, '/');
    }
  }

  newPaths[prefix.toString().toUpperCase()] = `/${prefix}`;
  return newPaths;
};

const Path = {
  ...preparePaths({ prefix: '/', paths: accountPaths }),
  ...preparePaths({ prefix: 'auth', paths: authPaths }),
  ...preparePaths({ prefix: 'setting', paths: settingPaths }),
  KITCHEN_SINK: '/kitchen-sink',
  SLASH: '/',
  NOT_FOUND: '/not-found',
  VERIFY: '/confirmUser',
  FORGOT: '/forgot',
  RESET_PASSWORD: '/resetPassword',
};

export default Path;

import lang from 'translations';
import { Path } from 'paths';

const navItems = () => {
  const items = [
    {
      to: Path.SLASH,
      text: lang.account,
      icon: 'gear',
      exact: true,
    },
  ];
  return items;
};

export default navItems;

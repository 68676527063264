import React from 'react';
import { Modal, ActionButton, Text } from 'components/commons';
import lang from 'translations';

const UnsaveChangesModal = ({ active, close, ok }) => {
  return (
    <Modal
      active={active}
      title={lang.youHaveUnsavedChanges}
      noCloseButton
      actionContent={
        <ActionButton
          className="px-md py-md"
          secondary={{
            text: lang.cancel,
            onClick: () => {
              close();
            },
          }}
          primary={{
            text: lang.discardChanges,
            onClick: () => {
              ok();
            },
          }}
        />
      }
    >
      <Text>{lang.ifYouLeaveThisPage}</Text>
    </Modal>
  );
};

export default UnsaveChangesModal;

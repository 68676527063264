import React from 'react';
import { Modal, ActionButton, Text } from 'components/commons';
import lang from 'translations';
// import { redirectTo } from 'services';
// import { Path } from 'paths';

const LogoutSessionModal = ({ active, close }) => {
  return (
    <Modal
      active={active}
      noCloseButton
      close={close}
      title={lang.loginSessionEnded}
      actionContent={
        <ActionButton
          className="px-md py-md"
          secondary={null}
          primary={{
            text: lang.ok,
            onClick: () => {
              close();
              localStorage.clear();
              // redirectTo(Path.AUTH);
            },
          }}
        />
      }
    >
      <Text>{lang.loginHasEnded}</Text>
    </Modal>
  );
};

export default LogoutSessionModal;

import React, { useCallback, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LayoutB } from 'components/layouts';

import { AccountSetting } from 'modules';
import { SideNav } from 'components/commons';
import navItems from './nav-items';
// import { ProfileContext } from 'contexts';
// import profileState from './profile.state';
// import { currentSession } from 'apis/aws.api';
import { currentSession } from 'apis/aws.api';
import { useApi, useMount } from 'hooks';
import { getAccessToken } from 'services/localStorage.service';
import { ProfileContext, AuthorizedContext, AppContext } from 'contexts';
import { getProfile } from 'apis';
// import { Path } from 'paths';

const AuthorizedPage = () => {
  const { logOutSessionModal } = useContext(AppContext);
  const { setProfile, setLoading } = useContext(ProfileContext);

  const requestProfile = useApi({
    api: getProfile,
  });

  const fetchProfile = useCallback(async () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      setLoading(true);
      if (localStorage.getItem('amplify-signin-with-hostedUI')) {
        try {
          const user = await currentSession();
          const payload = user.getIdToken().payload;
          const avatar = () => {
            const identity = payload?.identities?.[0]?.providerName;
            const identities = {
              Facebook: () => {
                return JSON.parse(payload.picture).data.url;
              },
            };
            if (identities[identity]) {
              return identities[identity]();
            }
            return payload.picture || null;
          };
          setProfile({
            email: payload.email,
            firstName: payload.given_name,
            lastName: payload.family_name,
            avatar: payload.picture ? avatar() : null,
            isReadOnly: true,
          });
        } catch (e) {
          console.error("Can't retrieve account", e);
          logOutSessionModal.show();
        }
      } else {
        const user = await requestProfile.request();
        setProfile(user);
        console.log(user);
      }
    }

    setLoading(false);
  }, [setLoading, setProfile, logOutSessionModal, requestProfile]);

  useMount(() => {
    fetchProfile();
  });

  return (
    <AuthorizedContext.Provider
      value={{
        fetchProfile,
      }}
    >
      <LayoutB sideMenu={<SideNav items={navItems()} />}>
        <Switch>
          {/* <Route exact path={'/account'} component={AccountSetting} /> */}
          <Route exact path={'/'} component={AccountSetting} />
        </Switch>
      </LayoutB>
    </AuthorizedContext.Provider>
  );
};

export default AuthorizedPage;

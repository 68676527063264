import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  AccountPage,
  AuthorizedPage,
  AuthPage,
  NotFoundPage,
  VerifyPage,
  ForgotPage,
  ResetPasswordPage,
} from 'pages';
import { AppContext } from 'contexts';

import { useState } from 'react';
import { Path } from 'paths';
import { AuthorizedRoute, AuthRoute } from 'routes';
import { useModal } from 'hooks';
import { LogoutSessionModal, SomethingWentWrongModal } from 'components/modals';
import { OctopusHiding, OctopusRain } from 'images';
import { Image, Loader, Text, Title } from 'components/commons';
import { LayoutA } from 'components/layouts';
import locale from 'translations';
import 'aws.config';
import { ProfileContext } from 'contexts';
import LogoutPage from 'pages/logout.page';
import SignInPage from 'pages/signin.page';
import { environment } from 'environments/environment';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

Sentry.init({
  dsn: environment.SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  environment: environment.id,
  tracesSampleRate: 1.0,
});

function App() {
  const [appState, setAppState] = useState({ error: false });
  const logOutSessionModal = useModal();
  const globalErrorModal = useModal();
  const [globalLoading, setGlobalLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);

  const [fetchingProfile, setFetchingProfile] = useState(false);
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  return (
    <AppContext.Provider
      value={{
        appState,
        setAppState,
        logOutSessionModal,
        globalErrorModal,
        pageErrorImage: OctopusRain,
        setGlobalLoading,
        setGlobalError: setError,
        sliderIndex,
        setSliderIndex,
      }}
    >
      <ProfileContext.Provider
        value={{ profile, setProfile, loading: fetchingProfile, setLoading: setFetchingProfile }}
      >
        <div className="App">
          {error ? (
            <LayoutA hasUserNav={false}>
              <div className="text-center">
                <Image className="m-auto" src={OctopusHiding} />
                <Title className="mt-md" xl color="text-blue">
                  {locale.somethingWentWrong}
                </Title>
                <Text> {locale.looksLikeYouEncounteredSomething}</Text>
              </div>
            </LayoutA>
          ) : (
            <Router>
              <Switch>
                <Route exact path={'/logout'} component={LogoutPage} />
                <Route path={'/sign/:id'} component={SignInPage} />
                <Route exact path={Path.NOT_FOUND} component={NotFoundPage} />
                <AuthRoute exact path={Path.VERIFY} component={VerifyPage} />
                <AuthRoute exact path={Path.FORGOT} component={ForgotPage} />
                <AuthRoute exact path={Path.RESET_PASSWORD} component={ResetPasswordPage} />
                <AuthRoute path={Path.AUTH} component={AuthPage} />
                <Route path={Path.CREATE_ACCOUNT} component={AccountPage} />
                <AuthorizedRoute path={Path.SLASH} component={AuthorizedPage} />
              </Switch>
            </Router>
          )}
        </div>
        <LogoutSessionModal {...logOutSessionModal} />
        <SomethingWentWrongModal {...globalErrorModal} />
        <div className="hidden">
          <Image src={OctopusRain} />
        </div>
        <Loader loading={globalLoading} />
      </ProfileContext.Provider>
    </AppContext.Provider>
  );
}

export default App;

import LayoutC from 'components/layouts/layout-c';
import React, { useCallback, useState } from 'react';
import { Field, Input, Button, Text, Title, ButtonLink, Image } from 'components/commons';
import lang from 'translations';
import { initialState } from './sign-in-form.state';
import { useApi, useForm, useRouter } from 'hooks';
import { Path } from 'paths';
import { useHistory, useLocation } from 'react-router-dom';
import { AWSErrorType, StyleType } from 'enums';
import { isEmptyObject } from 'services/object.service';
import GoogleIcon from 'images/google.svg';
import FacebookIcon from 'images/facebook.svg';
import { Auth } from 'aws-amplify';
import './styles.css';
import { redirectTo, redirectToCallbackURI } from 'services/url.service';
import { signInCognito } from '../../apis/auth.api';
import { environment } from 'environments/environment';
import jwt_encode from 'jwt-encode';
import { getProfile } from 'apis';
// import { currentSession } from 'apis/aws.api';

const SignIn = () => {
  const { query } = useRouter();
  const location = useLocation();
  const { redirectUrl, redirectValue, redirectKey } = query || {};

  const [passwordStatus, setPasswordStatus] = useState(true);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { fields, modifyField, applyFieldErrors, getFormValues } = useForm({
    initialState,
  });

  const validateFields = params => {
    const errorFields = {};
    Object.keys(params).forEach(key => {
      const field = params[key];
      if (!field.value || field.value.length === 0) {
        if (key === 'email') {
          errorFields[key] = lang.emailCanNotBeEmpty;
        }
        if (key === 'password') {
          errorFields[key] = lang.passwordCanNotBeEmpty;
        }
      }
    });
    return errorFields;
  };

  const requestProfile = useApi({
    api: getProfile,
  });

  const getUserDetails = useCallback(
    async accessToken => {
      if (accessToken) {
        if (!localStorage.getItem('amplify-signin-with-hostedUI')) {
          try {
            const user = await requestProfile.request();
            return {
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              avatar: user.avatar,
            };
          } catch (e) {
            return {};
          }
        }
      }
    },
    [requestProfile],
  );

  const doSignIn = useCallback(async () => {
    const errorFields = validateFields(fields);
    if (!isEmptyObject(errorFields)) {
      applyFieldErrors(errorFields);
      return;
    }
    setLoading(true);
    try {
      const loginResponse = await signInCognito({
        clientId: environment.POUCH_CLIENT_ID,
        cognitoClientId: environment.AWS.userPoolWebClientId,
        grantType: environment.POUCH_CLIENT_TYPE,
        email: fields.email.value,
        password: fields.password.value,
      });

      if (loginResponse.cognitoAccessToken) {
        localStorage.setItem('accessToken', loginResponse.cognitoAccessToken);
        localStorage.setItem('accessTokenV3', loginResponse.accessToken);

        if (redirectUrl) {
          const userInfo = await getUserDetails(loginResponse.accessToken);
          const userInfoEncode = await jwt_encode(userInfo, 'secret');
          redirectToCallbackURI({
            redirectUrl,
            idToken: loginResponse.cognitoIdToken,
            accessToken: loginResponse.cognitoAccessToken,
            redirectValue,
            redirectKey,
            userInfo: userInfoEncode,
          });
          return;
        }
      }
      redirectTo('/');
    } catch (error) {
      handleResponseError(error);
    }
    // eslint-disable-next-line
  }, [getFormValues, applyFieldErrors, modifyField]);

  const handleResponseError = useCallback(
    error => {
      localStorage.clear();
      switch (error.code) {
        case AWSErrorType.NotAuthorizedException:
          applyFieldErrors({
            email: '',
            password: lang.incorrectLoginParams,
          });
          break;
        case AWSErrorType.UserNotConfirmedException:
          applyFieldErrors({
            email: error?.message,
          });
          break;
        case AWSErrorType.InvalidParameterException:
          applyFieldErrors({
            email: '',
            password: error?.message,
          });
          break;
        default:
          applyFieldErrors({
            email: '',
            password: error.errorMessage || lang.somethingWentWrong,
          });
          break;
      }
      setLoading(false);
    },
    [applyFieldErrors],
  );

  const goToForgotPassword = useCallback(
    () => history.push(Path.FORGOT + location.search),
    [location, history],
  );

  const goToRegister = useCallback(
    () => history.push(Path.CREATE_ACCOUNT + location.search),
    [location, history],
  );

  const googleSignIn = useCallback(async () => {
    Auth.federatedSignIn({ provider: 'Google' });
  }, []);

  const facebookSignIn = useCallback(async () => {
    Auth.federatedSignIn({ provider: 'Facebook' });
  }, []);

  // const cloudbedsSignIn = useCallback(async () => {
  //   redirectTo(cloudbedUrl);
  // }, []);

  return (
    <LayoutC
      leftChild={
        <div>
          <Title xl>{lang.logIn}</Title>
          <Text>{lang.continueToYourPNAccount}</Text>
          <div className="flex flex-col">
            <Button
              type={StyleType.Tertiary}
              className="mt-md justify-between"
              iconPrefix={<Image src={GoogleIcon} />}
              iconSuffix={<Text />}
              onClick={googleSignIn}
            >
              <Text className="font-semibold text-gray-900 social-btn">
                {lang.continueWithGoogle}
              </Text>
            </Button>
            <Button
              type={StyleType.Tertiary}
              className="mt-md justify-between"
              iconPrefix={<Image src={FacebookIcon} />}
              iconSuffix={<Text />}
              onClick={facebookSignIn}
            >
              <Text className="font-semibold text-gray-900 social-btn">
                {lang.continueWithFacebook}
              </Text>
            </Button>

            {environment.id === 'dev' && (
              <Input
                inputType={'text'}
                onChange={(name, obj) => {
                  redirectTo(obj.value);
                }}
              />
            )}
          </div>
          <div className="divider">or</div>
          <div className="py-lg">
            <Field
              {...fields.email}
              customLabel={
                <Text size="text-xs" color="text-gray-500 font-semibold">
                  {lang.emailAddress}
                </Text>
              }
            >
              <Input {...fields.email} onChange={modifyField} />
            </Field>
            <Field
              {...fields.password}
              className="mt-md"
              customLabel={
                <Text size="text-xs" color="text-gray-500 font-semibold">
                  {lang.password}
                </Text>
              }
            >
              <Input
                {...fields.password}
                inputType={passwordStatus ? 'password' : ''}
                onChange={(name, obj) => {
                  modifyField(name, obj);
                }}
                iconSuffix={
                  <ButtonLink
                    onClick={() => {
                      setPasswordStatus(!passwordStatus);
                    }}
                    prefix
                    icon={passwordStatus ? 'eye-open' : 'eye-close'}
                    iconClassName="text-lg"
                  />
                }
                onEnter={() => doSignIn()}
              />
            </Field>
            <div className="flex flex-wrap justify-between">
              <Button onClick={doSignIn} disabled={loading} loading={loading}>
                {lang.logIn}
              </Button>
              <ButtonLink onClick={goToForgotPassword}>{lang.forgotPassword}</ButtonLink>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-6">
              <div className="col-span-2">
                <Text className="mt-xl text-gray-600">{lang.dontHaveAccountYet}</Text>
              </div>
              <div className="col-span-2">
                <ButtonLink
                  className="mt-0 lg:mt-xl text-pelorous-dark -ml-0 xl:-ml-8"
                  onClick={goToRegister}
                >
                  {lang.createAnAccountNow}
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default SignIn;

import React from 'react';
import { Image, UserNav } from 'components/commons';
import { PouchnationLogo } from 'images';
import { redirectTo } from 'services';
import classNames from 'classnames';
import styles from './page-header.module.scss';
import { useRouter } from 'hooks';

const PageHeader = ({ logoRedirectUrl, hasUserNav = true }) => {
  const { history } = useRouter();
  return (
    <div
      className={classNames(
        'bg-washed h-15 min-h-15 z-20 w-full flex items-center justify-between fixed',
        styles.container,
      )}
    >
      <div className="px-4 md:px-8">
        <Image
          className="cursor-pointer max-w-r10 sm:max-w-r12"
          src={PouchnationLogo}
          onClick={() => {
            if (logoRedirectUrl) {
              redirectTo(logoRedirectUrl);
            } else {
              history.push('/');
            }
          }}
        />
      </div>
      {hasUserNav && <UserNav />}
    </div>
  );
};

export default PageHeader;

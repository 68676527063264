export const environment = {
  id: 'production',
  production: true,
  API_V3: 'https://gateway.pouchnation.com',
  API_COGNITO: 'https://api.connect.pouchnation.com',
  FIREBASE: {
    apiKey: 'AIzaSyBwTQaxZOqLXOCDe0Aytj8vw9qPB1UkJVo',
    authDomain: 'ms-communication-ae098.firebaseapp.com',
    databaseURL: 'https://ms-communication-ae098.firebaseio.com',
    projectId: 'ms-communication-ae098',
    storageBucket: 'ms-communication-ae098.appspot.com',
    messagingSenderId: '139918626078',
    appId: '1:139918626078:web:c8900ad0dffdb794538d0f',
    measurementId: 'G-BG2Z7LL2BK',
  },
  MIXPANEL_TOKEN: '763af5c0f625347770feb6a1867ec07c',
  FEATURE_FLAG: {
    clientSideID: '5f7ad02fe750890b17de445e',
  },
  SENTRY_DSN: 'https://0f17215f2e754bdb9688618dde61881f@o431184.ingest.sentry.io/6152555',
  ACTIVE_MQ_HOST:
    'wss://b-02a26402-ef02-4539-8267-bd7552996a10-1.mq.ap-southeast-1.amazonaws.com:61619',
  ACTIVE_MQ_USER: 'pnprod',
  ACTIVE_MQ_PW: 'pouchprod123!Xd1jd',
  VENUE_URL: 'https://venue.pouchnation.com',
  VENUE_PAGE: 'https://venue.pouchnation.com/redirect',
  POUCH_CONNECT: 'https://connect.pouchnation.com/',
  POUCH_CLIENT_ID: 'POUCH_ID',
  POUCH_CLIENT_TYPE: 'email',
  AWS: {
    userPoolId: 'ap-southeast-1_l30lGIHyR',
    oauthDomain: 'auth-connect-pouchnation-com.auth.ap-southeast-1.amazoncognito.com',
    userPoolWebClientId: '4kns1ntimiv4mrb7a3aavvunkk',
    redirectSignIn: 'https://accounts.pouchnation.com/auth',
    redirectSignOut: 'https://accounts.pouchnation.com',
    scope: 'booking/user connector/user email openid profile aws.cognito.signin.user.admin',
  },
  PABBLY_PIPEDRIVE_WEBHOOK: 'https://connect.pabbly.com/workflow/sendwebhookdata/IjE3ODg4OSI_3D',
};

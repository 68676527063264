import React from "react";
import { Route, Switch } from "react-router-dom";
import { SignIn, SignOut} from "modules";

const AuthPage = () => {
  return (
    <Switch>
      <Route path="/" component={SignIn} />
      <Route path="/logout" component={SignOut} />
    </Switch>
  );
};

export default AuthPage;

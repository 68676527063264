import { Field as FieldType } from 'enums';
import Validation from 'services/validation.service';
import lang from 'translations';

export const initialState = {
  password: {
    name: 'password',
    value: '',
    type: FieldType.INPUT,
    validations: [Validation.required(), Validation.maxlength(16), Validation.minlength(8)],
    required: true,
    minLength: 8,
    maxLength: 16,
    placeholder: lang.enterNewPassword,
  },
  confirmPassword: {
    name: 'confirmPassword',
    value: '',
    type: FieldType.INPUT,
    validations: [Validation.required(), Validation.maxlength(16), Validation.minlength(8)],
    required: true,
    minLength: 8,
    maxLength: 16,
  },
};

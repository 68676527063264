import { NotFound } from 'modules';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const NotFoundPage = () => {
  return (
    <Switch>
      <Route path="/" component={NotFound} />
    </Switch>
  );
};

export default NotFoundPage;

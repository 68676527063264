import { environment } from 'environments/environment';
const CognitoIdentityServiceProvider = 'CognitoIdentityServiceProvider';

export const getLastAuthUser = (userPoolId = environment.AWS.userPoolWebClientId) => {
  return localStorage.getItem(`${CognitoIdentityServiceProvider}.${userPoolId}.LastAuthUser`);
};

export const getUserData = (userPoolId = environment.AWS.userPoolWebClientId) => {
  const lastAuthUser = getLastAuthUser(userPoolId);
  const userData = localStorage.getItem(
    `${CognitoIdentityServiceProvider}.${userPoolId}.${lastAuthUser}.userData`,
  );
  return JSON.parse(`${userData}`)?.UserAttributes?.reduce(
    // eslint-disable-next-line
    (acc, current) => ((acc[current.Name] = current.Value), acc),
    {},
  );
};

export const getAccessToken = (userPoolId = environment.AWS.userPoolWebClientId) => {
  const lastAuthUser = getLastAuthUser(userPoolId);
  const accessToken = localStorage.getItem(
    `${CognitoIdentityServiceProvider}.${userPoolId}.${lastAuthUser}.accessToken`,
  );
  if (accessToken) {
    return accessToken;
  }
  return localStorage.getItem('accessToken');
};

export const getRefreshToken = (userPoolId = environment.AWS.userPoolWebClientId) => {
  const lastAuthUser = getLastAuthUser(userPoolId);
  return localStorage.getItem(
    `${CognitoIdentityServiceProvider}.${userPoolId}.${lastAuthUser}.refreshToken`,
  );
};

export const getIdToken = (userPoolId = environment.AWS.userPoolWebClientId) => {
  const lastAuthUser = getLastAuthUser(userPoolId);
  return localStorage.getItem(
    `${CognitoIdentityServiceProvider}.${userPoolId}.${lastAuthUser}.idToken`,
  );
};

export const redirectTo = url => {
  window.location.href = url;
};

export const redirectToCallbackURI = ({
  redirectUrl,
  idToken,
  accessToken,
  redirectValue = '',
  redirectKey = '',
  accessTokenV3 = '',
  userInfo,
}) => {
  let parameters = new URLSearchParams('');
  if (idToken) {
    parameters.append('idToken', idToken);
  }
  if (accessToken) {
    parameters.append('accessToken', accessToken);
  }
  parameters.append('state', '1');
  parameters.append('accessTokenV3', accessTokenV3);
  if (userInfo) {
    parameters.append('userInfo', userInfo);
  }

  if (redirectValue && redirectKey) {
    const paramValue = redirectValue.split(',');
    redirectKey.split(',').forEach((key, index) => {
      if (paramValue?.[index]) {
        parameters.append(key, paramValue[index]);
      }
    });
  }
  redirectTo(`${redirectUrl}#${parameters.toString()}`);
};

export const reloadPage = () => {
  window.location.reload();
};

export const addQueryParam = (path, obj) => {
  let url = '';
  Object.keys(obj).forEach(key => {
    const param = `${key}=${obj[key]}`;
    url = url ? `${url}&${param}` : param;
  });
  return `${path}?${url}`;
};

export const isURI = uri => {
  const pattern = new RegExp(
    /(?:^|[ \t])((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/gm,
  );
  return pattern.test(uri);
};

import { Field as FieldType } from 'enums';
import Validation from 'services/validation.service';
import lang from 'translations';

export const initialState = {
  email: {
    name: 'email',
    placeholder: lang.enterYourEmailAddress,
    value: '',
    type: FieldType.INPUT,
    validations: [
      Validation.required({ customMessage: lang.pleaseEnterYourEmail }),
      Validation.emailAddress({ customMessage: lang.pleaseEnterValidEmail }),
    ],
    maxLength: 75,
    required: true,
  },
  firstName: {
    name: 'firstName',
    value: '',
    type: FieldType.INPUT,
    validations: [Validation.required()],
    required: true,
    maxLength: 75,
    placeholder: lang.enterYourFirstName,
  },
  lastName: {
    name: 'lastName',
    value: '',
    type: FieldType.INPUT,
    validations: [Validation.required()],
    required: true,
    maxLength: 75,
    placeholder: lang.enterYourLastName,
  },
  password: {
    name: 'password',
    value: '',
    type: FieldType.INPUT,
    validations: [Validation.required(), Validation.maxlength(16), Validation.minlength(8)],
    required: true,
    minLength: 8,
    maxLength: 16,
    placeholder: lang.setUpAPassword,
  },
  confirmPassword: {
    name: 'confirmPassword',
    value: '',
    type: FieldType.INPUT,
    validations: [Validation.required(), Validation.maxlength(16), Validation.minlength(8)],
    required: true,
    minLength: 8,
    maxLength: 16,
    placeholder: lang.retypePassword,
  },
};

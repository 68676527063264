const locale = {
  account: 'Cuenta',
  accountCreatedSuccessfully: 'La cuenta está activa',
  allRightsReserved: 'Todos los derechos reservados.',
  almostDoneResetPassword: '¡Ya casi está! Introduce tu nueva contraseña.',
  alreadyHaveAnAccount: '¿Ya tienes una cuenta?',
  and: 'y',
  andClickResetPassword: 'y haga clic en el vínculo Restablecer contraseña para restablecerla',
  backToLogin: 'Volver a iniciar sesión',
  byGettingStarted: 'Al empezar, estás de acuerdo con PouchNATION',
  canNotLoginSocialNetwork: 'No se puede iniciar sesión mediante redes sociales',
  cancel: 'Cancelar',
  changePassword: 'Cambiar la contraseña',
  changePhoto: 'Cambiar foto',
  changesSaved: 'Cambios guardados.',
  chooseAPassword: 'Utiliza una contraseña fuerte que no utilices en otro sitio.',
  code: 'Código',
  completeYourDetails: 'Completa tus datos',
  confirmNewPassword: 'Confirmar nueva contraseña',
  confirmPassword: 'Confirmar contraseña',
  contactUs2: 'Contacta con nosotros',
  continueToYourAccount: 'Continúa a tu cuenta',
  continueToYourPNAccount: 'Continúa a tu cuenta PouchNATION',
  continueWithFacebook: 'Continuar con Facebook',
  continueWithGoogle: 'Continuar con Google',
  copyrightYear: 'Copyright © 2021',
  createAccount: 'Crear cuenta',
  createAnAccountNow: 'Crear una cuenta ahora',
  createYourAccount: 'Crea tu cuenta',
  currentPassword: 'Contraseña actual',
  currentPasswordAndNewPasswordMatched: 'La contraseña actual y la nueva coinciden',
  didNotReceiveAnyThing: '¿No has recibido nada?',
  discard: 'Descartar',
  discardChanges: 'Descartar cambios',
  dontHaveAccountYet: '¿Aún no tienes una cuenta?',
  edit: 'Editar',
  emailAddress: 'Dirección de e-mail',
  emailAlreadyHaveAnAccount:
    'Esta dirección de e-mail ya está asociada a una cuenta. Por favor, inténtalo con otra.',
  emailCanNotBeEmpty: 'El e-mail no puede dejarse en blanco',
  emailNotFound: 'E-mail no encontrado',
  emailNotMatchAccount: 'Este correo electrónico no coincide con una cuenta de PouchNATION.',
  emailVerification: 'Verifica la dirección de e-mail',
  enterEmailToReset: 'Introduce el e-mail asociado a tu cuenta para restablecer tu contraseña.',
  enterNewPassword: 'Introduce tu nueva contraseña',
  enterVerificationCode: 'Introduce el código de verificación',
  enterYourEmailAddress: 'Introduce tu dirección de e-mail',
  enterYourFirstName: 'Introduce tu nombre',
  enterYourLastName: 'Introduce tus apellidos',
  enterYourPassword: 'Introduce tu contraseña',
  experiencePouchVenues:
    'Sácale el máximo partido a PouchVENUEs con la creación de menús, la gestión de inventarios, la gestión de huéspedes y mucho más.',
  feedback: 'Comentarios',
  finishSettingUpYourAccount: 'Termina de configurar tu cuenta de PouchNATION.',
  firstName: 'Nombre',
  forgotPassword: '¿Has olvidado tu contraseña?',
  freeTrial: '¡30 días de prueba gratuita!',
  freeTrial2: '30 días de prueba gratuita',
  getStarted: 'Empezar',
  getStartedWithA: 'Comience con un',
  glossary: 'Glosario',
  goToHome: 'Ir a la página de inicio',
  goToVenue: 'Ir al panel de control del espacio',
  haveNotReceivedIt: '¿Aún no lo has recibido?',
  ifYouLeaveThisPage:
    'Si abandonas esta página, todos los cambios no guardados se perderán. ¿Seguro que quieres descartar los cambios?',
  incorrectLoginParams: 'E-mail o contraseña incorrectos',
  invalidFileType: 'Tipo de archivo no válido. Los tipos de archivo permitidos son JPG o PNG.',
  invalidLogin: 'Inicio de sesión no válido',
  invalidOptions: 'Opción(es) no válida(s).',
  isAreserveWord: 'es una palabra de reserva.',
  lastName: 'Apellidos',
  logIn: 'Iniciar sesión',
  loginHasEnded: 'Tu sesión ha ha finalizado. Por favor, inicia sesión de nuevo.',
  loginInstead: 'Iniciar sesión',
  loginNow: 'Iniciar sesión ahora',
  loginSessionEnded: 'Sesión de inicio de sesión finalizada',
  logout: 'Cerrar sesión',
  looksLikeYouEncounteredSomething:
    'Parece que ha habido un error. Hacemos un seguimiento de estos errores automáticamente. Mientras tanto, actualiza la sesión.',
  maximumLengthCharacters: 'Máximo de {0} caracteres',
  maximumValueIs: 'El valor máximo es ',
  minOfNCharacters: 'Mínimo de {0} caracteres',
  minimum: 'Mínimo',
  minimumCharactersAllowed: 'Mínimo de {0} caracteres permitidos',
  minimumOfNCharacters: 'Mínimo de 8 caracteres',
  minimumValueIs: 'El valor mínimo es ',
  newPassWord: 'Nueva contraseña',
  newToPouchNation: '¿Eres nuevo en PouchNATION?',
  ok: 'Entendido',
  pageNotFound: 'Página no encontrada',
  password: 'Contraseña',
  passwordCanNotBeEmpty: 'La contraseña no puede estar vacía',
  passwordDoesNotMatch: 'Las contraseñas introducidas no coinciden',
  passwordIncorrect: 'La contraseña que has introducido no es correcta.',
  passwordResetExpired: 'La solicitud de restablecimiento de contraseña ha caducado.',
  passwordResetSuccessful: '¡Contraseña reestablecida con éxito!',
  pleaseCheck: 'por favor verifique',
  pleaseEnterValidEmail: 'Por favor, introduce una dirección de e-mail válida.',
  pleaseEnterYourEmail:
    'Por favor, introduce tu dirección de e-mail para empezar a crear una cuenta.',
  pleaseEnterYourEmailToReset:
    'Por favor, introduce tu dirección de e-mail para restablecer tu cuenta.',
  pouchnation: 'PouchNATION',
  privacy: 'Privacidad',
  privacyPolicy2: 'Política de privacidad',
  rememberedYourCurrentPassword: '¿Has recordado tu contraseña actual?',
  resend: 'Reenviar enlace',
  resendResetLink: 'Reenviar enlace',
  resetPassword: 'Restablecer contraseña',
  resetPasswordLinkSent: 'Enlace de restablecimiento de contraseña enviado',
  retypePassword: 'Vuelve a escribir la contraseña',
  roadMap: 'Hoja de ruta',
  save: 'Guardar',
  sendResetLink: 'Enviar enlace de restablecimiento',
  sentCodeInfo: '',
  setUpAPassword: 'Configurar una contraseña',
  setUpYourPouchNationAccount: 'Configure tu cuenta de PouchNATION.',
  setUpYourVenue: 'Configura tu espacio',
  shouldBeANumber: 'Debe ser un número.',
  signIn: 'Iniciar sesión',
  somethingWentWrong: 'Algo ha salido mal.',
  subscribeAndRegister: 'Suscríbete y regístrate ahora para obtener un',
  termsAndConditions: 'Términos y condiciones',
  termsOfUse: 'Condiciones de uso',
  thisEmailIsInvalid: 'Este e-mail no es válido.',
  thisFieldIsRequired: 'Este campo es obligatorio.',
  today: 'Hoy',
  unbaleToUploadMoreThanMb: 'No se puede cargar más de 1mb.',
  uploadPhoto: 'Subir foto',
  valueBetween: 'El valor debe estar entre',
  values: 'valor(es)',
  verifyEmailAddressFailed: 'La verificación de la dirección de e-mail ya está confirmada.',
  verifyEmailAddressSuccessful: 'Verificación de la dirección de e-mail procesada con éxito',
  verifyEmailDone: 'Tu dirección de e-mail ha sido verificada',
  viewPlansAndPricing: 'Ver planes y precios',
  youCanNowUseNewPassword:
    '¡Tu contraseña se ha cambiado con éxito!Ya puedes utilizar tu nueva contraseña para acceder a tu cuenta.',
  youHaveUnsavedChanges: 'Tienes cambios sin guardar en esta página.',
  yourPasswordRequestExpired: 'Tu solicitud de restablecimiento de contraseña ha caducado.',
};
export default Object.freeze(locale);

export const profileRequest = {
  venueId: {
    key: 'venueId',
  },
  profileId: {
    key: 'profileId',
  },
  firstName: {
    key: 'firstName',
  },
  lastName: {
    key: 'lastName',
  },
};

export const profileCreateRequest = {
  username: {
    key: 'email',
  },
  password: {
    key: 'password',
  },
  attributes: {
    transform: ({ src }) => {
      return { given_name: src.firstName, family_name: src.lastName };
    },
  },
};

export const profileCreateRequestV3 = {
  email: {
    key: 'email',
  },
  password: {
    key: 'password',
  },
  profile: {
    transform: ({ src }) => {
      return {
        firstName: src.firstName,
        lastName: src.lastName,
        registrationMark: src.registrationMark,
      };
    },
  },
};

import { Get, Post, Put } from 'services';
import { ApiPath } from 'paths';
import { v1 as uuidv1 } from 'uuid';

export const uploadProfileAvatar = async ({ file }) => {
  const formData = new FormData();
  const fileName = uuidv1();
  formData.append('file', file, fileName);
  const res = await Post(`${ApiPath.PROFILE_AVATAR}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data;
};

export const updateProfileData = async body => {
  const res = await Put(`${ApiPath.PROFILE_USERS_PROFILE_ID(body.profileId)}`, {
    ...body,
    accessTokenV3: true,
  });
  return res.data;
};

export const updatePassword = async body => {
  body.accessTokenV3 = true;
  const res = await Put(`${ApiPath.PROFILE_CHANGE_PASSWORD}`, body);
  return res.data;
};

export const forgotPassword = async ({ email }) => {
  const res = await Get(`${ApiPath.FORGOT_PASSWORD(email)}`);
  return res.data;
};

export const resetPassword = async body => {
  const res = await Post(`${ApiPath.RESET_PASSWORD}`, body);
  return res.data;
};

import { Field as FieldType } from 'enums';
import Validation from 'services/validation.service';
import lang from 'translations';

export const initialState = {
  email: {
    name: 'email',
    placeholder: lang.enterYourEmailAddress,
    value: '',
    type: FieldType.INPUT,
    validations: [
      Validation.required({ customMessage: lang.pleaseEnterYourEmailToReset }),
      Validation.emailAddress({ customMessage: lang.pleaseEnterValidEmail }),
    ],
    maxLength: 75,
    required: true,
  },
};

import enumeration from './enumeration';

const UserLanguage = {
  en: 'English',
  es: 'Spanish',
  pt: 'Portuguese',
  th: 'Thai',
};

export default enumeration(UserLanguage);

import React, { useCallback, useState } from 'react';
import { Route } from 'react-router-dom';
import { Path } from 'paths';
import { useApi, useMount, useRouter } from 'hooks';
import { getAccessToken, getIdToken } from 'services/localStorage.service';
import { redirectTo } from 'services';
import { redirectToCallbackURI } from 'services/url.service';
import { Icon } from 'components/commons';
import jwt_decode from 'jwt-decode';
import jwt_encode from 'jwt-encode';
import * as moment from 'moment';
import { getProfile } from 'apis';
import { currentSession } from 'apis/aws.api';

// import file from './login.csv';
// import axios from 'axios';

const AuthRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const { query } = useRouter();
  const { clientId, redirectUrl, redirectValue, redirectKey } = query || {};
  const accessToken = getAccessToken();
  const idToken = getIdToken();

  const requestProfile = useApi({
    api: getProfile,
  });
  const getUserDetails = useCallback(async () => {
    if (getAccessToken()) {
      if (localStorage.getItem('amplify-signin-with-hostedUI')) {
        try {
          const user = await currentSession();
          const payload = user.getIdToken().payload;
          const avatar = () => {
            const identity = payload?.identities?.[0]?.providerName;
            const identities = {
              Facebook: () => {
                return JSON.parse(payload.picture).data.url;
              },
            };
            if (identities[identity]) {
              return identities[identity]();
            }
            return payload.picture || null;
          };
          return {
            email: payload.email,
            firstName: payload.given_name,
            lastName: payload.family_name,
            avatar: payload.picture ? avatar() : null,
          };
        } catch (e) {
          console.log(e);
        }
      } else {
        const user = await requestProfile.request();
        return {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          avatar: user.avatar,
        };
      }
    }
  }, [requestProfile]);

  const redirectPage = useCallback(async () => {
    // try {
    const userInfo = await getUserDetails();
    const userInfoEncode = await jwt_encode(userInfo, 'secret');
    return redirectToCallbackURI({
      redirectUrl,
      idToken,
      accessToken,
      redirectKey,
      redirectValue,
      userInfo: userInfoEncode,
    });
    // } catch (e) {
    //   redirectTo(Path.SLASH);
    // }
  }, [getUserDetails, accessToken, idToken, redirectKey, redirectUrl, redirectValue]);

  useMount(async () => {
    // const item = await axios.get(file);
    // item.data.split('\n').forEach(i => {
    //   const [key = '', value = ''] = i.split('	');
    //   localStorage.setItem(key, value);
    // });
    if (accessToken || localStorage.getItem('amplify-redirected-from-hosted-ui')) {
      if (!localStorage.getItem('accessToken')) {
        localStorage.setItem('accessToken', getAccessToken());
      }
      if (redirectUrl && clientId) {
        try {
          const user = jwt_decode(accessToken);

          if (moment().isAfter(moment(user.exp * 1000))) {
            setLoading(false);
            return;
          }
        } catch (e) {
          console.log(e);
        }

        await redirectPage();
      } else {
        redirectTo(Path.SLASH);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  });

  if (loading) {
    return (
      <div className="w-full text-center flex h-full">
        <Icon className="m-auto" loading />
      </div>
    );
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default AuthRoute;

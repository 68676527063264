import React from 'react';
import { Text } from 'components/commons';
import lang from 'translations';
import LangSelector from './lang-selector';

const PageFooter = () => {
  return (
    <div className="mt-sm">
      <div className="flex flex-col lg:flex-row xl:flex-row 2xl:flex-row sm:flex-col min-h-15 px-8 justify-center items-center">
        <Text size="text-xs" className="text-gray-600 px-md my-sm">
          {lang.copyrightYear}{' '}
          <a href="https://pouchnation.com" target="_blank" rel="noreferrer" className="text-xs">
            {lang.pouchnation}
          </a>
          . {lang.allRightsReserved}
        </Text>

        <Text size="text-xs" className="text-gray-400 px-md my-sm">
          <a
            href="https://pouchnation.com/terms/"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.termsOfUse}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400 px-md my-sm">
          <a
            href="https://pouchnation.com/privacy/"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.privacy}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400 px-md my-sm">
          <a
            href="https://pouchnation.atlassian.net/wiki/external/1136001061/NzliOGQ5MmM4MjA3NDI4NGFhZjE4ZDE1YTk3NWNlMjY"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.glossary}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400 px-md my-sm">
          <a
            href="https://feedback.pouchnation.com/"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.feedback}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400 px-md my-sm">
          <a
            href="https://feedback.pouchnation.com/roadmap"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.roadMap}
          </a>
        </Text>

        <Text size="text-xs" className="text-gray-400 px-md my-sm">
          <a
            href="https://pouchnation.com/contact-us/"
            target="_blank"
            rel="noreferrer"
            className="text-xs"
          >
            {lang.contactUs2}
          </a>
        </Text>
        <div className="flex lg:ml-auto justify-center lg:justify-end items-center">
          {<LangSelector />}
        </div>
      </div>
    </div>
  );
};

export default PageFooter;

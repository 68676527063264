import LayoutC from 'components/layouts/layout-c';
import React, { useCallback, useState, useMemo } from 'react';
import {
  Field,
  Input,
  Button,
  Text,
  Title,
  ButtonLink,
  Line,
  Image,
  Toast,
} from 'components/commons';
import lang from 'translations';
import { initialState } from './create-account-form.state';
import { useApi, useForm, useRouter } from 'hooks';
import { signInV3, signUp } from 'apis';
import { mapObject } from 'services';
import { profileCreateRequest } from 'mappers/profile.mapper';
import { environment } from 'environments/environment';
import { AWSErrorType, ClientId } from 'enums';
import { useHistory, useLocation } from 'react-router-dom';
import { notFoundEmail } from 'apis/aws.api';
import { TrustBox } from 'images';
import { getAccessToken } from 'services/localStorage.service';
import { registerAccount } from 'apis/account.api';
import { signInCognito } from 'apis/auth.api';
import { Path } from 'paths';
// import { pipedriveWebhook } from 'apis/pabbly.api';

const CreateAccount = () => {
  const { query } = useRouter();
  const location = useLocation();
  const { redirectUrl, registrationMark, clientId } = query || {};

  const history = useHistory();

  const [validatedEmail, setValidatedEmail] = useState(false);
  const [validatedEmailOnce, setValidatedEmailOnce] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [loading, setLoading] = useState(false);

  const { fields, modifyField, applyFieldErrors, getFormValues, isFormSubmittable } = useForm({
    initialState,
  });

  // const pipedriveWebhookRequest = useApi({
  //   api: pipedriveWebhook,
  //   handleOwnError: {
  //     badrequest: true,
  //   },
  // });

  const verifyEmail = useCallback(
    async (stopLoading = true) => {
      console.log("VERIFYING EMAIL!");

      setLoading(true);
      if (fields.email.value !== '' && !fields.email.error) {
        const emailNotExisted = await notFoundEmail(fields.email.value);
        if (!validatedEmail) {
          setValidatedEmail(emailNotExisted);
        }

        if (!emailNotExisted) {
          applyFieldErrors({ email: lang.emailAlreadyHaveAnAccount });
        } else {
          setValidatedEmailOnce(emailNotExisted);
          if (environment.id) {
            if (environment.id === 'production' || environment.id === 'prod') {
              //pipedriveWebhookRequest.request({ email: fields.email.value, name: '' });
            }
          }
        }
      } else if (fields.email.value === '') {
        modifyField('email', { error: true });
        if (!validatedEmail) {
          setValidatedEmail(false);
        }
      }
      if (stopLoading) {
        setLoading(false);
      }

      // eslint-disable-next-line
    },
    [modifyField, fields.email, applyFieldErrors, validatedEmail],
  );

  const doSignIn = useCallback(async () => {
    setLoading(true);
    try {
      const loginResponse = await signInCognito({
        clientId: environment.POUCH_CLIENT_ID,
        cognitoClientId: environment.AWS.userPoolWebClientId,
        grantType: environment.POUCH_CLIENT_TYPE,
        email: fields.email.value,
        password: fields.password.value,
      });

      localStorage.setItem('accessToken', loginResponse.cognitoAccessToken);
      localStorage.setItem('accessTokenV3', loginResponse.accessToken);

      if (redirectUrl) {
        let url = new URL(redirectUrl);
        let parameters = new URLSearchParams(url.search.slice(1));
        parameters.append('accessToken', loginResponse.cognitoAccessToken);
        parameters.append('idToken', loginResponse.cognitoIdToken);
        parameters.append('accessTokenV3', loginResponse.accessToken);
        parameters.append('state', '1');

        if (clientId === ClientId.VenueDashboard) {
          window.location.href = `${redirectUrl}?${parameters.toString()}`;
        } else {
          window.location.href = `${
            Path.AUTH
          }?${parameters.toString()}&redirectUrl=${redirectUrl}&clientId=${clientId}`;
        }
      } else {
        window.location.href = '/';
      }
    } catch (error) {
      console.log(error);
      Toast({
        content: lang.looksLikeYouEncounteredSomething,
        success: false,
        icon: 'error',
      }).open();
    }
    // eslint-disable-next-line
  }, [getFormValues, applyFieldErrors, modifyField]);

  // const goToApplication = useCallback(async () => {
  //   const accessToken = getAccessToken();
  //   const idToken = getIdToken();
  //   if (redirectUrl) {
  //     redirectToCallbackURI({ redirectUrl, idToken, accessToken });
  //   } else {
  //     window.location.href = `/`;
  //   }
  // }, []);

  const validatePasswords = useCallback(
    (value1, value2) => {
      const match = value1 === value2;
      if (
        fields.confirmPassword &&
        fields.confirmPassword.value &&
        fields.confirmPassword.value.length > 0
      ) {
        setPasswordsMatch(match);
      } else {
        setPasswordsMatch(true);
      }
    },
    [setPasswordsMatch, fields.confirmPassword],
  );

  const { request: signInRequest } = useApi({
    api: signInV3,
    handleOwnError: true,
  });

  const { request: registerAccountRequest } = useApi({
    api: registerAccount,
    handleOwnError: {
      badrequest: true,
    },
  });

  const doSignInV3 = useCallback(async () => {
    const params = getFormValues();

    try {
      const res = await signInRequest({
        email: params.email,
        password: params.password,
        clientId: clientId ?? 'POUCH_ID',
      });
      localStorage.setItem('accessToken', res?.cognitoAccessToken);
      localStorage.setItem('accessTokenV3', res?.accessToken);

      if (redirectUrl) {
        let url = new URL(redirectUrl);
        let parameters = new URLSearchParams(url.search.slice(1));

        if (clientId && clientId === ClientId.VenueDashboard) {
          parameters.append('accessToken', res?.accessToken);
        } else {
          parameters.append('accessToken', res?.cognitoAccessToken);
        }
        if (clientId === ClientId.VenueDashboard) {
          window.location.href = `${redirectUrl}?${parameters.toString()}`;
        } else {
          window.location.href = `${
            Path.AUTH
          }?${parameters.toString()}&redirectUrl=${redirectUrl}&clientId=${clientId}`;
        }
      } else {
        history.push('/');
      }
    } catch (err) {
      console.log(err);
      Toast({
        content: lang.looksLikeYouEncounteredSomething,
        success: false,
        icon: 'error',
      }).open();
    }
  }, [signInRequest, clientId, getFormValues, history, redirectUrl]);

  const createAccountInV3 = useCallback(async () => {
    const params = getFormValues();

    params.profile = {
      firstName: params.firstName,
      lastName: params.lastName,
      registrationMark: registrationMark,
    };

    if (clientId) {
      params.clientId = clientId;
      if (clientId === ClientId.VenueDashboard) {
        params.profile.profileType = 'VENUE';
        params.profile.uniqueType = 'VENUE';
        params.profile.forType = 'VENUE';
      }
    }

    try {
      await registerAccountRequest(params);
      if (clientId && clientId === ClientId.Connector) {
        doSignIn();
      } else {
        doSignInV3();
      }

      Toast({
        content: lang.accountCreatedSuccessfully,
        success: true,
        icon: 'check',
      }).open();
    } catch (err) {
      setLoading(false);
      modifyField('email', { error: true });
      if (!validatedEmail) {
        setValidatedEmail(false);
      }
    }
    // eslint-disable-next-line
  }, [doSignInV3, registerAccountRequest, clientId, doSignIn, getFormValues, registrationMark]);

  const handleSignUp = useCallback(async () => {
    try {
      //await verifyEmail(false);
      setLoading(true);
      if (validatedEmail) {
        const params = getFormValues();
        await signUp(mapObject(params, profileCreateRequest));

        if (environment.production) {
          var dataLayer = window.dataLayer || [];
          dataLayer.push({
            event: 'account-created',
          });
        }

        const accessToken = getAccessToken();
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
        }
        createAccountInV3();
      }
    } catch (error) {
      console.log("Error in registration")
      console.log(error);
      // might be a broken registration in cognito > v3. try v3 instead?
      if (
        ![AWSErrorType.UsernameExistsException, AWSErrorType.InvalidPasswordException].includes(
          error.code,
        )
      ) {
        console.error('Error received on first try. Retrying v3...');
        // try v3 if error is not a validation
        await createAccountInV3();
      }
      setLoading(false);
      handleSignUpError(error);
    }

    // eslint-disable-next-line
  }, [getFormValues]);

  const getStartedTitle = useMemo(() => {
    if (registrationMark === 'ENTERPRISE') {
      return (
        <Title xl>
          {lang.getStarted} {''}
          <span className="text-xl text-black-light font-bold no-underline shadow-text">
            {lang.today}
          </span>
        </Title>
      );
    }
    return (
      <Title xl>
        {lang.getStartedWithA} {''}
        <span className="text-xl text-black-light font-bold no-underline shadow-text">
          {lang.freeTrial2}
        </span>
      </Title>
    );
  }, [registrationMark]);

  const title = useMemo(() => {
    if (validatedEmailOnce) {
      return lang.completeYourDetails;
    }
    return getStartedTitle;
  }, [validatedEmailOnce, getStartedTitle]);

  const subTitle = useMemo(() => {
    if (validatedEmailOnce) {
      return lang.finishSettingUpYourAccount;
    }
    return lang.experiencePouchVenues;
  }, [validatedEmailOnce]);

  const handleSignUpError = error => {
    switch (error.code) {
      case AWSErrorType.ExpiredCodeException:
      case AWSErrorType.CodeMismatchException:
        applyFieldErrors({ verificationCode: error?.message });
        break;
      case AWSErrorType.UsernameExistsException:
        applyFieldErrors({ email: lang.emailAlreadyHaveAnAccount });
        break;
      case AWSErrorType.InvalidPasswordException:
        applyFieldErrors({ password: error?.message });
        break;
      default:
        break;
    }
  };

  const goToLogin = useCallback(() => {
    if (clientId === ClientId.VenueDashboard) {
      window.location.href = `${environment.VENUE_URL}/auth?lang=${localStorage.getItem('locale')}`;
    } else {
      history.push(`/auth${location.search}`);
    }
  }, [location, history, clientId]);

  const submitButtonDisabled = useMemo(() => {
    return validatedEmail ? !isFormSubmittable || !passwordsMatch : false || loading;
  }, [validatedEmail, isFormSubmittable, passwordsMatch, loading]);

  return (
    <LayoutC
      leftChild={
        <div>
          <Title className="pb-0 xxl:pb-1.5" xl>
            {title}
          </Title>
          <Text>{subTitle}</Text>
          <div className="pt-sm pb-md xxl:py-lg">
            <Field
              {...fields.email}
              customLabel={
                <Text size="text-xs" color="text-gray-500 font-semibold">
                  {lang.emailAddress}
                </Text>
              }
            >
              <Input
                {...fields.email}
                onChange={(name, value) => {
                  modifyField(name, value);
                  // setValidatedEmail(false);
                }}
                onEnter={() => verifyEmail()}
              ></Input>
            </Field>
            {validatedEmailOnce && (
              <div>
                <div className="flex justify-content items-left">
                  <Field
                    {...fields.firstName}
                    className="mt-0 xxl:mt-md"
                    customLabel={
                      <Text size="text-xs" color="text-gray-500 font-semibold">
                        {lang.firstName}
                      </Text>
                    }
                  >
                    <Input {...fields.firstName} onChange={modifyField} />
                  </Field>
                  <Field
                    {...fields.lastName}
                    className="mt-0 ml-sm xxl:mt-md"
                    customLabel={
                      <Text size="text-xs" color="text-gray-500 font-semibold">
                        {lang.lastName}
                      </Text>
                    }
                  >
                    <Input {...fields.lastName} onChange={modifyField} />
                  </Field>
                </div>

                <Field
                  {...fields.password}
                  className="mt-0 xxl:mt-md"
                  customLabel={
                    <Text size="text-xs" color="text-gray-500 font-semibold">
                      {lang.password}
                    </Text>
                  }
                >
                  <Input
                    {...fields.password}
                    inputType={passwordStatus ? 'password' : ''}
                    onChange={(name, obj) => {
                      modifyField(name, obj);
                      validatePasswords(obj.value, fields.confirmPassword.value);
                    }}
                    iconSuffix={
                      <ButtonLink
                        onClick={() => {
                          setPasswordStatus(!passwordStatus);
                        }}
                        prefix
                        icon={passwordStatus ? 'eye-open' : 'eye-close'}
                        iconClassName="text-lg"
                      ></ButtonLink>
                    }
                  />
                </Field>
                <Field
                  {...fields.confirmPassword}
                  customMessage={
                    !passwordsMatch ? <Text error>{lang.passwordDoesNotMatch}</Text> : null
                  }
                  className="mt-0 xxl:mt-md"
                  customLabel={
                    <Text size="text-xs" color="text-gray-500 font-semibold">
                      {lang.confirmPassword}
                    </Text>
                  }
                >
                  <Input
                    {...fields.confirmPassword}
                    error={!passwordsMatch || fields.confirmPassword.error}
                    inputType={passwordStatus ? 'password' : ''}
                    onChange={(name, obj) => {
                      modifyField(name, obj);
                      validatePasswords(obj.value, fields.password.value);
                    }}
                    onEnter={handleSignUp}
                  />
                </Field>
              </div>
            )}
          </div>
          {
            <>
              {validatedEmailOnce ? (
                ''
              ) : (
                <div className="mb-md">
                  <Text size="text-xs" color="text-gray-400">
                    {lang.byGettingStarted}{' '}
                    <a
                      href="https://pouchnation.com/terms/"
                      target="_blank"
                      className="text-xs underline"
                      rel="noreferrer"
                    >
                      {lang.termsAndConditions}
                    </a>{' '}
                    {lang.and}{' '}
                    <a
                      href="https://pouchnation.com/privacy/"
                      target="_blank"
                      className="text-xs underline"
                      rel="noreferrer"
                    >
                      {lang.privacyPolicy2}
                    </a>
                  </Text>
                </div>
              )}
              <Button
                onClick={() => {
                  if (submitButtonDisabled || loading) {
                    return false;
                  }
                  if (validatedEmail) {
                    return handleSignUp();
                  } else {
                    verifyEmail();
                  }
                }}
                disabled={submitButtonDisabled || loading}
                loading={loading}
              >
                {validatedEmailOnce ? lang.createAccount : lang.getStarted}
              </Button>
              <div className="flex justify-content items-left">
                <Text className="mt-md xl:mt-xl xxl:mt-xxl text-gray-600">
                  {lang.alreadyHaveAnAccount}
                </Text>
                <ButtonLink
                  className="mt-md xl:mt-xl xxl:mt-xxl text-pelorous-dark ml-sm"
                  onClick={goToLogin}
                >
                  {lang.loginInstead}
                </ButtonLink>
              </div>
              <div className="pt-2 xxl:pt-4">
                <Line className="my-sm xxl:my-md"></Line>
              </div>
              <Image className="w-32 md:max-w-lg" src={TrustBox} />
            </>
          }
        </div>
      }
    ></LayoutC>
  );
};

export default CreateAccount;

import { ForgotPassword } from 'modules';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const AccountPage = () => {
  return (
    <Switch>
      <Route path="/" component={ForgotPassword} />
    </Switch>
  );
};

export default AccountPage;

import enumeration from './enumeration';

const ErrorType = {
  NotAuthorizedException: 'NotAuthorizedException',
  InvalidParameterException: 'InvalidParameterException',
  UsernameExistsException: 'UsernameExistsException',
  InvalidPasswordException: 'InvalidPasswordException',
  ExpiredCodeException: 'ExpiredCodeException',
  CodeMismatchException: 'CodeMismatchException',
  UserNotConfirmedException: 'UserNotConfirmedException',
  UserNotFoundException: 'UserNotFoundException',
  AliasExistsException: 'AliasExistsException',
};

export default enumeration(ErrorType);

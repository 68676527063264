import { Icon } from 'components/commons';
import { environment } from 'environments/environment';
import { useMount, useRouter } from 'hooks';
import { Path } from 'paths';
import React from 'react';

const SignInPage = () => {
  const { query, history } = useRouter();
  const { id } = query || {};

  useMount(() => {
    const referrers = {
      connector: [`${environment.POUCH_CONNECT}auth`, 'CONNECTOR'],
    };
    const referrer = referrers[id];
    if (referrer) {
      history.push(`${Path.AUTH}?clientId=${referrer[1]}&redirectUrl=${referrer[0]}`);
    }
  });
  return (
    <div>
      <Icon loading />
    </div>
  );
};

export default SignInPage;

import { Auth } from 'aws-amplify';
import { AWSErrorType } from 'enums';

const signUp = ({ username, password, attributes }) =>
  Auth.signUp({
    username,
    password,
    attributes,
  });

const confirmSignUp = ({ username, code }) => Auth.confirmSignUp(username, code);

const signIn = ({ email, password }) => {
  return Auth.signIn(email, password);
};

const resendConfirmationCode = ({ username }) => Auth.resendSignUp(username);

const getCurrentSession = () => Auth.currentSession();

const forgotPassword = ({ username }) => Auth.forgotPassword(username);
const forgotPasswordSubmit = ({ username, code, newPassword }) =>
  Auth.forgotPasswordSubmit(username, code, newPassword);

const code = '00';

const notFoundEmail = async username => {
  try {
    await Auth.confirmSignUp(username, code, {
      forceAliasCreation: false,
    });
  } catch (error) {
    switch (error.code) {
      case AWSErrorType.UserNotFoundException:
        return true;
      default:
        return false;
    }
  }
};

const getCurrentAuthenticatedUser = () => Auth.currentAuthenticatedUser();
const getUserInfo = user => Auth.userAttributes(user);
const currentUserInfo = () => Auth.currentUserInfo();
const currentUserCredentials = () => Auth.currentUserCredentials();
const currentSession = () => Auth.currentSession();

async function signOut() {
  try {
    // pass params {global: true} to signOut users from all devices
    await Auth.signOut();
  } catch (error) {
    throw error;
  }
}

export {
  signUp,
  confirmSignUp,
  signIn,
  resendConfirmationCode,
  signOut,
  getCurrentSession,
  forgotPassword,
  forgotPasswordSubmit,
  notFoundEmail,
  getCurrentAuthenticatedUser,
  currentUserInfo,
  currentUserCredentials,
  currentSession,
  getUserInfo,
};

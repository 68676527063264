import React from 'react';
import { PageHeader, Container, PageFooter } from 'components/commons';
import styles from './layout-b.module.scss';
import classnames from 'classnames';

const LayoutB = ({ children, sideMenu }) => {
  return (
    <div className="h-full layout-b">
      <PageHeader hasUserNav={true} />
      <div className="fixed h-full z-10 pt-14">{sideMenu}</div>
      <div className={classnames(styles.content, 'md:ml-side ml-xl pt-12')}>
        <Container className={classnames(`p-xxs md:p-lg`, styles.child)}>{children}</Container>
        <PageFooter />
      </div>
    </div>
  );
};

export default LayoutB;

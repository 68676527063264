import { profilePaths } from './profile.path';
import { authPaths } from './auth.path';
import venuePaths from './inventory/venue.path';

const preparePaths = ({ prefix, paths }) => {
  let newPaths = {};

  for (const [k, path] of Object.entries(paths)) {
    if (typeof path === 'function') {
      newPaths[k] = id => `${prefix}/${path(id)}`.replace(/\/+/g, '/');
    } else if (typeof path === 'string') {
      if (path.length > 0) {
        newPaths[k] = `${prefix}/${path}`.replace(/\/+/g, '/');
      } else {
        newPaths[k] = `${prefix}`.replace(/\/+/g, '/');
      }
    } else {
      newPaths[k] = path;
    }
  }
  return newPaths;
};

const ApiPath = {
  ...preparePaths({ prefix: 'auth', paths: authPaths }),
  ...preparePaths({
    prefix: 'profile',
    paths: profilePaths,
  }),
  ...preparePaths({
    prefix: 'storage',
    paths: {
      STORAGE_UPLOAD: 'upload',
    },
  }),
  ...preparePaths({ prefix: 'inventory/venue', paths: venuePaths }),
  CHECK_EMAIL: 'inventory/profile/staff/check',
  LOGIN_COGNITO: 'user/login',
};

export default ApiPath;

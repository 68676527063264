import React from "react";
import { Skeleton as Loader } from "antd";
import styles from "./skeleton.module.scss";

const Skeleton = ({ single, className }) => {
  if (single) {
    return (
      <Loader.Button active={true} size={"small"} shape={"square"} className={styles.container} />
    );
  }
  return <Loader width="100%" active={true} className={className} />;
};

export default Skeleton;

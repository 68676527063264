const locale = {
  account: 'บัญชี',
  accountCreatedSuccessfully: 'สร้างบัญชีสำเร็จ',
  allRightsReserved: 'สงวนลิขสิทธิ์',
  almostDoneResetPassword: 'ใกล้เสร็จแล้ว! ป้อนรหัสผ่านใหม่ของคุณ',
  alreadyHaveAnAccount: 'มีบัญชีอยู่แล้ว?',
  and: 'และ',
  andClickResetPassword: 'และคลิกการเชื่อมโยงตั้งค่ารหัสผ่านใหม่เพื่อตั้งค่ารหัสผ่านของคุณใหม่',
  backToLogin: 'กลับไปเข้าสู่ระบบ',
  byGettingStarted: 'สำหรับการเริ่มใช้งาน คุณได้ยอมรับ PouchNATION',
  canNotLoginSocialNetwork: 'ไม่สามารถเข้าสู่ระบบด้วยโซเชียลเน็ตเวิร์ก',
  cancel: 'ยกเลิก',
  changePassword: 'เปลี่ยนรหัสผ่าน',
  changePhoto: 'เปลี่ยนรูป',
  changesSaved: 'บันทึกการเปลี่ยนแปลงแล้ว',
  chooseAPassword: 'ใช้รหัสผ่านที่ปลอดภัยซึ่งไม่ได้ใช้ที่อื่น',
  code: 'รหัส',
  completeYourDetails: 'กรอกรายละเอียดให้สมบูรณ์',
  confirmNewPassword: 'ยืนยันรหัสผ่านใหม่',
  confirmPassword: 'ยืนยันรหัสผ่าน',
  contactUs2: 'ติดต่อเรา',
  continueToYourAccount: 'ดำเนินการต่อไปที่บัญชีของคุณ',
  continueToYourPNAccount: 'ดำเนินการต่อไปยังบัญชี PouchNATION ของคุณ',
  continueWithFacebook: 'ดำเนินการต่อด้วย Facebook',
  continueWithGoogle: 'ดำเนินการต่อด้วย Google',
  copyrightYear: 'ลิขสิทธิ์ © 2021',
  createAccount: 'สร้างบัญชี',
  createAnAccountNow: 'สร้างบัญชีตอนนี้',
  createYourAccount: 'สร้างบัญชีของคุณ',
  currentPassword: 'รหัสผ่านปัจจุบัน',
  currentPasswordAndNewPasswordMatched: 'รหัสผ่านปัจจุบันและรหัสผ่านใหม่ตรงกัน',
  didNotReceiveAnyThing: 'ได้รับหรือไม่?',
  discard: 'ยกเลิก',
  discardChanges: 'ยกเลิกการเปลี่ยนแปลง',
  dontHaveAccountYet: 'ยังไม่มีบัญชีใช่หรือไม่?',
  edit: 'แก้ไข',
  emailAddress: 'ที่อยู่อีเมล',
  emailAlreadyHaveAnAccount: 'ที่อยู่อีเมลนี้มีบัญชีอยู่แล้ว โปรดลองอีกครั้ง',
  emailCanNotBeEmpty: 'ช่องอีเมลห้ามเว้นว่าง',
  emailNotFound: 'ไม่พบอีเมล',
  emailNotMatchAccount: 'อีเมลนี้ไม่ตรงกับบัญชี PouchNATION',
  emailVerification: 'ยืนยันที่อยู่อีเมล',
  enterEmailToReset: 'ป้อนที่อยู่อีเมลที่เชื่อมโยงกับบัญชีของคุณเพื่อรีเซ็ตรหัสผ่าน',
  enterNewPassword: 'ป้อนรหัสผ่านใหม่ของคุณ',
  enterVerificationCode: 'ป้อนรหัสยืนยัน',
  enterYourEmailAddress: 'ป้อนที่อยู่อีเมลของคุณ',
  enterYourFirstName: 'ป้อนชื่อของคุณ',
  enterYourLastName: 'ป้อนนามสกุลของคุณ',
  enterYourPassword: 'ป้อนรหัสผ่านของคุณ',
  experiencePouchVenues:
    'สัมผัสประสิทธิภาพของ PouchVENUEs ด้านการสร้างเมนู การจัดการรายการสินค้า ระบบดูแลแขก และอื่นๆ อีกมากมาย!',
  feedback: 'ข้อเสนอแนะ',
  finishSettingUpYourAccount: 'ตั้งค่าบัญชี PouchNATION ของคุณให้เสร็จสิ้น',
  firstName: 'ชื่อจริง',
  forgotPassword: 'ลืมรหัสผ่าน?',
  freeTrial: 'ทดลองใช้งานฟรี 30 วัน!',
  freeTrial2: 'ทดลองใช้งานฟรี 30 วัน',
  getStarted: 'เริ่ม',
  getStartedWithA: 'เริ่มต้นใช้งาน',
  glossary: 'อภิธานศัพท์',
  goToHome: 'ไปที่หน้าแรก',
  goToVenue: 'ไปที่แดชบอร์ดสถานที่',
  haveNotReceivedIt: 'ยังไม่ได้รับ?',
  ifYouLeaveThisPage:
    'หากคุณออกจากหน้านี้ การเปลี่ยนแปลงที่ไม่ได้บันทึกทั้งหมดจะสูญหายไป คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการเปลี่ยนแปลง',
  incorrectLoginParams: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
  invalidFileType: 'ประเภทไฟล์ไม่ถูกต้อง ประเภทไฟล์ที่อนุญาตคือ JPG หรือ PNG',
  invalidLogin: 'เข้าสู่ระบบไม่ถูกต้อง',
  invalidOptions: 'ตัวเลือกที่ไม่ถูกต้อง',
  isAreserveWord: 'เป็นคําสํารอง.',
  lastName: 'นามสกุล',
  logIn: 'เข้าสู่ระบบ',
  loginHasEnded: 'เซสชั่นการเข้าสู่ระบบของคุณสิ้นสุดแล้ว กรุณาเข้าสู่ระบบอีกครั้ง',
  loginInstead: 'เข้าสู่ระบบแทน',
  loginNow: 'ลงชื่อเข้าใช้ตอนนี้',
  loginSessionEnded: 'สิ้นสุดเซสชันการเข้าสู่ระบบ',
  logout: 'ออกจากระบบ',
  looksLikeYouEncounteredSomething:
    'ดูเหมือนว่าคุณพบข้อผิดพลาด เราจะติดตามข้อผิดพลาดเหล่านี้โดยอัตโนมัติ ในระหว่างนี้ โปรดลองรีเฟรช',
  maximumLengthCharacters: 'ระดับสูงสุด {0} ตัวอักษร',
  maximumValueIs: 'ค่าสูงสุดคือ',
  minOfNCharacters: 'ขั้นต่ำ {0} ตัวอักษร',
  minimum: 'ขั้นต่ํา',
  minimumCharactersAllowed: 'อย่างน้อย {0} อักขระที่ใช้ได้',
  minimumOfNCharacters: 'ขั้นต่ำ 8 ตัวอักษร',
  minimumValueIs: 'ค่าต่ําสุดคือ',
  newPassWord: 'รหัสผ่านใหม่',
  newToPouchNation: 'ยังไม่เคยสมัครสมาชิกกับ PouchNATION ใช่หรือไม่?',
  ok: 'ตกลง',
  pageNotFound: 'ไม่พบหน้านี้',
  password: 'รหัสผ่าน',
  passwordCanNotBeEmpty: 'รหัสผ่านต้องไม่เว้นว่าง',
  passwordDoesNotMatch: 'รหัสผ่านที่คุณป้อนไม่ตรงกัน',
  passwordIncorrect: 'รหัสผ่านที่คุณป้อนไม่ถูกต้อง',
  passwordResetExpired: 'คำขอรีเซ็ตรหัสผ่านหมดอายุ!',
  passwordResetSuccessful: 'รีเซ็ตรหัสผ่านสำเร็จ!',
  pleaseCheck: 'โปรดตรวจสอบ',
  pleaseEnterValidEmail: 'กรุณาใส่อีเมล์ที่ถูกต้อง',
  pleaseEnterYourEmail: 'โปรดป้อนที่อยู่อีเมลของคุณเพื่อเริ่มสร้างบัญชี',
  pleaseEnterYourEmailToReset: 'โปรดป้อนที่อยู่อีเมลของคุณเพื่อรีเซ็ตบัญชีของคุณ',
  pouchnation: 'PouchNATION',
  privacy: 'ความเป็นส่วนตัว',
  privacyPolicy2: 'นโยบายความเป็นส่วนตัว',
  rememberedYourCurrentPassword: 'จำรหัสผ่านปัจจุบันของคุณได้หรือไม่?',
  resend: 'ส่งลิงก์อีกครั้ง',
  resendResetLink: 'ส่งลิงก์รีเซ็ตอีกครั้ง',
  resetPassword: 'รีเซ็ตรหัสผ่าน',
  resetPasswordLinkSent: 'ลิงก์รีเซ็ตรหัสผ่านส่งแล้ว!',
  retypePassword: 'พิมพ์รหัสผ่านอีกครั้ง',
  roadMap: 'แผนงาน',
  save: 'บันทึก',
  sendResetLink: 'ส่งลิงก์รีเซ็ต',
  sentCodeInfo: 'ส่งข้อมูลโค้ด',
  setUpAPassword: 'ตั้งรหัสผ่าน',
  setUpYourPouchNationAccount: 'ตั้งค่าบัญชี PouchNATION ของคุณ',
  setUpYourVenue: 'ตั้งค่าสถานที่ของคุณ',
  shouldBeANumber: 'ต้องเป็นตัวเลข',
  signIn: 'เข้าสู่ระบบ',
  somethingWentWrong: 'อะไรบางอย่างผิดปกติ',
  subscribeAndRegister: 'สมัครสมาชิกและลงทะเบียนตอนนี้เพื่อรับ',
  termsAndConditions: 'ข้อกำหนดและเงื่อนไข',
  termsOfUse: 'เงื่อนไขการใช้บริการ',
  thisEmailIsInvalid: 'อีเมลนี้ไม่ถูกต้อง',
  thisFieldIsRequired: 'จำเป็นต้องระบุ',
  today: 'วันนี้',
  unbaleToUploadMoreThanMb: 'ไม่สามารถอัปโหลดเกิน 1mb',
  uploadPhoto: 'อัพโหลดรูปภาพ',
  valueBetween: 'ค่าควรอยู่ระหว่าง',
  values: 'ค่า',
  verifyEmailAddressFailed: 'การตรวจสอบที่อยู่อีเมลได้รับการยืนยันแล้ว',
  verifyEmailAddressSuccessful: 'ยืนยันที่อยู่อีเมลสำเร็จ!',
  verifyEmailDone: 'ที่อยู่อีเมลของคุณได้รับการยืนยันแล้ว',
  viewPlansAndPricing: 'ดูแผนและราคา',
  youCanNowUseNewPassword:
    'รหัสผ่านของคุณถูกเปลี่ยนเรียบร้อยแล้ว! คุณสามารถใช้รหัสผ่านใหม่เพื่อเข้าสู่ระบบบัญชีของคุณได้แล้ว',
  youHaveUnsavedChanges: 'คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึกในหน้านี้',
  yourPasswordRequestExpired: 'คำขอรีเซ็ตรหัสผ่านของคุณหมดอายุแล้ว',
};
export default Object.freeze(locale);

import React, { useState, useMemo } from 'react';
import { Text, Dropdown, Icon } from 'components/commons';
import { useMount } from 'hooks';
import lang from 'translations';
import UserLanguage from 'enums/user-language';
import { redirectTo } from 'services';
import classnames from 'classnames';

const LangSelector = () => {
  const { _language } = lang;
  const [active, setActive] = useState(_language);

  const languagePack = [
    {
      text: 'English',
      key: 'en',
    },
    {
      text: 'Spanish',
      key: 'es',
    },
    {
      text: 'Portuguese',
      key: 'pt',
    },
    {
      text: 'Thai',
      key: 'th',
    },
  ];

  const initLanguage = useMemo(() => {
    if (_language === 'en') {
      setActive('en');
      return UserLanguage.en;
    } else if (_language === 'es') {
      setActive('es');
      return UserLanguage.es;
    } else if (_language === 'pt') {
      setActive('pt');
      return UserLanguage.pt;
    } else if (_language === 'th') {
      setActive('th');
      return UserLanguage.th;
    }
  }, [_language]);

  const [language, setLocale] = useState(initLanguage);

  useMount(() => {
    localStorage.setItem('locale', _language);
    console.log(_language);
  });

  const languageOptions = useMemo(() => {
    return languagePack.map(language => {
      return {
        ...language,
        text: (
          <Text
            size="text-xs"
            className={classnames(
              'hover:text-pelorous',
              active === language.key ? 'text-pelorous' : 'text-gray',
            )}
          >
            {language.text}
          </Text>
        ),
        value: language.text,
        onClick: () => {
          setLocale(language.value);
          setActive(language.key);
          const query = new URLSearchParams(window.location.search);
          query.delete('lang');
          const currentPath = window.location.pathname;
          localStorage.setItem('locale', language.key);
          redirectTo(`${currentPath}${query.toString() === '' ? '' : `?${query.toString()}`} `);
        },
      };
    });
    // eslint-disable-next-line
  }, [active]);

  return (
    <Dropdown
      trigger="click"
      placement="topLeft"
      className="-mr-1 cursor-pointer"
      options={languageOptions}
    >
      <div className="flex items-center justify-center">
        <Text size="text-xs">{language}</Text>
        <Icon name="sort-descending" className="ml-1 mb-3" fontSize="text-sm" />
      </div>
    </Dropdown>
  );
};

export default LangSelector;

import React from 'react';
import { Container, Logo } from 'components/commons';
import { Slider, SliderRightSideData } from 'components/commons/slider';
import { Background } from 'images';
import LangSelector from 'components/commons/page-footer/lang-selector';

const LayoutC = ({ leftChild, rightChild }) => {
  return (
    <div className="min-h-screen flex flex-row bg-white overflow-y-auto relative">
      <div className="w-full md:w-1/2">
        <Container className="h-full grid grid-cols-1 items-stretch justify-items-center">
          <div className="self-center w-5/6 h-full pt-5 flex flex-col justify-between">
            <Logo className="h-10" />
            <div className="ml-sm mt-sm">{leftChild}</div>
            <div className="w-5/6 ml-sm mt-sm flex items-end">
              <LangSelector />
            </div>
          </div>
        </Container>
      </div>
      <div
        className=" bg-pelorous w-0 md:w-1/2 transition-all duration-500"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
        }}
      >
        {rightChild ? rightChild : <Slider items={SliderRightSideData} infinite />}
      </div>
    </div>
  );
};

export default LayoutC;

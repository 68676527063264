import { Field } from 'enums';
import Validation from 'services/validation.service';
import lang from 'translations';

export const initialState = {
  email: {
    name: 'email',
    placeholder: lang.enterYourEmailAddress,
    value: '',
    type: Field.INPUT,
    validations: [
      Validation.required({ noMessage: true }),
      Validation.emailAddress({ noMessage: true }),
    ],
    maxLength: 75,
  },
  password: {
    name: 'password',
    placeholder: lang.enterYourPassword,
    value: '',
    type: Field.INPUT,
    // validations: [Validation.required({})],
    maxLength: 75,
  },
};

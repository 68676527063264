import React from 'react';
import { useRouter } from 'hooks';
import { Icon } from 'components/commons';
import classnames from 'classnames';
import styles from './button.module.scss';
const ButtonLink = ({
  className,
  children,
  path,
  onClick,
  icon,
  suffix = false,
  prefix = !suffix,
  loading,
  flex = true,
  iconClassName = 'text-xs',
  size = 'text-sm',
  anchor,
  disabled,
}) => {
  const { history } = useRouter();

  const renderIcon = icon ? <Icon loading={loading} name={icon} className={iconClassName} /> : null;

  const content = (
    <>
      {prefix && renderIcon}
      {children}
      {suffix && renderIcon}
    </>
  );
  if (anchor) {
    return (
      <a
        className={classnames('text-pelorous', size ? size : 'text-md', className)}
        rel="noreferrer"
        href={path}
      >
        {content}
      </a>
    );
  }

  return (
    <button
      type="button"
      className={classnames('cursor-pointer inline-block', className, size, styles.buttonLink, {
        'flex items-center': flex,
        'text-gray cursor-not-allowed': disabled,
        'text-pelorous': !disabled,
      })}
      onClick={() => {
        if (disabled) {
          return false;
        }
        if (path) {
          const win = window.open(path, '_blank');
          win.focus();
        } else {
          if (onClick) {
            onClick();
            return;
          }
          history.push(path);
        }
      }}
    >
      {content}
    </button>
  );
};

export default ButtonLink;

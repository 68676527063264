import { Icon } from 'components/commons';
import { useMount, useRouter } from 'hooks';
import { Path } from 'paths';
import React from 'react';

const LogoutPage = () => {
  const { query, history } = useRouter();
  const { redirectUrl } = query || {};

  useMount(() => {
    localStorage.clear();
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      history.push(Path.SLASH);
    }
    // window.close();
  });
  return (
    <div className="w-full flex h-full">
      <Icon loading className="m-auto" />
    </div>
  );
};

export default LogoutPage;
